import { IWorkspace } from '@/types/workspace';
import { create } from 'zustand';
import ReplugLogo from '../assets/images/logos/replug-favicon.svg';
import { isWhitelabelDomain, saveWhitelabelSettings } from '@/utils/CommonUtility';
import { APP_URL } from '@/constants/Constants';

interface IWorkspaceStore {
  defaultWorkspace: IWorkspace | null;
  setDefaultWorkspace: (workspace: IWorkspace | null) => void;
  workspaces: IWorkspace[] | [];
  setWorkspaces: (workspaces: IWorkspace[]) => void;
  createToggle: boolean;
  setCreateToggle: (toggle: boolean) => void;
  manageWorkspaceToggle: boolean;
  setManageWorkspaceToggle: (toggle: boolean) => void;
}

export const useWorkspaceStore = create<IWorkspaceStore>((set) => ({
  defaultWorkspace: null,
  createToggle: false,
  setCreateToggle: (toggle) => {
    set({ createToggle: toggle });
  },
  setDefaultWorkspace: (workspace) => {
    if (!workspace) return;

    // check if workspace is not empty (as we are setting default workspace to empty on logout)
    // and set whitelabel related data
    if (workspace._id) {
      workspace.agency = {
        logo: ReplugLogo,
        favicon: ReplugLogo,
        name: 'Replug',
        app_domain: workspace?.organization?.whitelabel?.is_whitelabel_enabled ? workspace.organization.whitelabel?.app_domain : APP_URL,
        is_whitelabeled: false,
        is_whitelabel_enabled: workspace.organization.whitelabel?.is_whitelabel_enabled || false, // added for switch to whitelabel btn
      }
      if (workspace?.organization?.whitelabel?.is_whitelabel_enabled && isWhitelabelDomain()) {
        workspace.agency = {
          is_whitelabeled: true,
          logo: workspace.organization.whitelabel?.logo || ReplugLogo,
          favicon: workspace.organization.whitelabel?.favicon || ReplugLogo,
          name: workspace.organization.whitelabel?.agency_name || 'Replug',
          app_domain: workspace.organization.whitelabel?.app_domain || APP_URL,
          is_whitelabel_enabled: true,
        }
      }
      // save whitelabel data to local storage(used in index.html to show whitelabel data before app loads)
      saveWhitelabelSettings(workspace.agency);
    }

    set({ defaultWorkspace: workspace });
  },
  workspaces: [],
  setWorkspaces: (workspaces) => {
    set({ workspaces });
  },
  manageWorkspaceToggle: false,
  setManageWorkspaceToggle: (toggle) => {
    set({ manageWorkspaceToggle: toggle });
  },
}));
