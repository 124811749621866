import { Box, Button, Flex, Title } from '@mantine/core';
import classes from '../../BioLinks.module.css';
import { IThemeCard, createContrastGradientBackground } from '@replug/utils';

interface IBioThemeCard {
  theme: IThemeCard;
  onClick: (theme: IThemeCard) => void;
}

export const BioThemeCard = ({ theme, onClick }: IBioThemeCard) => {
  return (
    <>
      <Box
        className={classes.bioTheme}
        bg={
          theme.backgroundStyle === 'gradient'
            ? createContrastGradientBackground(theme.backgroundColor, 30)
            : theme.backgroundColor
        }
        onClick={() => {
          onClick(theme);
        }}
      >
        <Flex direction={'column'} justify={'center'} h={'100%'} align={'center'}>
          {[0, 1, 2].map((i) => (
            <>
              <Button
                radius={theme.buttonStyles.radius || 'md'}
                style={{
                  backgroundColor: theme.buttonStyles.backgroundColor,
                  color: theme.buttonStyles.color,
                  border: theme.buttonStyles.border,
                  boxShadow: theme.buttonStyles?.boxShadow
                    ? theme.buttonStyles.boxShadow
                    : undefined,
                }}
                w={140}
                h={32}
                variant={theme.buttonStyles.variant}
                my={'sm'}
              ></Button>
            </>
          ))}
        </Flex>
      </Box>
    </>
  );
};
