import { Flex, Title, Text, Image, Box } from '@mantine/core';
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import '@mantine/carousel/styles.css';
import DeepLinkingImage from '../../assets/images/auth/deep-linking.png';
import TrafficRoutingImage from '../../assets/images/auth/traffic-routing.png';
import ABTestingImage from '../../assets/images/auth/ab-testing.png';
import classes from './AuthCarousel.module.css';

const FEATURES = [
  {
    title: 'New Feature: Deep Links',
    description:
      ' Create links to navigate users to the Google Play Store or Apple Store and download the intended app. Play videos and songs directly from an appropriate app to increase app installations.',
    image: DeepLinkingImage,
  },
  {
    title: 'New Feature: Traffic Routing',
    description:
      'Traffic routing rules empower you to target specific audiences, audience segmentation, and the ability to have multiple URL destinations. Get total control of your links, all intending to boost your conversions.',
    image: TrafficRoutingImage,
  },
  {
    title: 'New Feature: A/B Testing',
    description:
      'Webpage A/B testing gives you insight on which page performs better based on conversion rates, session times, and bounce rate. Create high converting marketing campaigns by A/B testing your landing pages. ',
    image: ABTestingImage,
  },
];
export function AuthCarousel() {
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  return (
    <Flex
      style={{
        height: '100%',
        width: '100%',
      }}
      //   align="center"
      justify="center"
    >
      <Box w={600} mt="20%">
        <Carousel
          loop
          height={500}
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          withIndicators
          withControls={false}
          classNames={{ indicator: classes.indicator }}
        >
          {FEATURES.map((feature, index) => (
            <>
              <Carousel.Slide key={index}>
                <Title ta="center" order={2} mb={16}>
                  {feature.title}
                </Title>
                <Text mb={16} ta="center">
                  {feature.description}
                </Text>
                <Image src={feature.image} />
              </Carousel.Slide>
            </>
          ))}
        </Carousel>
      </Box>
    </Flex>
  );
}
