import { useUserStore } from '@/stores/useUserStore';
import { Avatar, Box, Center, Divider, Flex, Grid, Title } from '@mantine/core';
import { ProfileChangePassword } from './ProfileChangePassword';
import { ProfileSave } from './ProfileSave';
import { useDocumentTitle } from '@mantine/hooks';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

export function ProfilePage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  useDocumentTitle('Account Profile | ' + defaultWorkspace?.agency?.name);

  const [user] = useUserStore((state) => [state.user, state.setUser]);
  const getAvatarInitials = () => {
    if (user) {
      // @ts-ignore
      return user.first_name[0] + user.last_name[0];
    }
    return '';
  };
  return (
    <Box
      style={{
        backgroundColor: 'var(--mantine-color-white)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        height: '100%',
        flexGrow: 1,
      }}
      ml={16}
      mr={16}
      pb={100}
    >
      <Box p={16}>
        <Center>
          <Title order={5}>Profile Settings</Title>
        </Center>
      </Box>
      <Divider color="gray.2" />

      <Grid gutter={32} mt={64}>
        <Grid.Col span={3}>
          <Flex align={'center'} justify={'end'} mt={64}>
            <Avatar
              mr={32}
              color={'primary'}
              styles={{
                placeholder: {
                  textTransform: 'uppercase',
                },
              }}
              w={128}
              h={128}
              size={'xl'}
            >
              {getAvatarInitials()}
            </Avatar>
          </Flex>
        </Grid.Col>
        <Grid.Col span={4}>
          <ProfileSave />
        </Grid.Col>
        <Grid.Col span={4}>
          <ProfileChangePassword />
        </Grid.Col>
      </Grid>
    </Box>
  );
}
