import { CampaignsService } from '@/services/CampaignsService';
import { create } from 'zustand';

interface ICampaign {
  _id: string;
  name: string;
  is_call_to_action_enabled: boolean;
}
interface ICampaignsStore {
  date: {
    label: string;
    value: string;
  };
  totalResults: number;
  setTotalResults: (totalResults: number) => void;
  setDate: (date: { label: string; value: string }) => void;
  page: number;
  setPage: (page: number) => void;
  lastPage: number;
  setLastPage: (lastPage: number) => void;
  archive: boolean;
  setArchive: (archive: boolean) => void;
  search: string;
  setSearch: (search: string) => void;
  campaigns: ICampaign[];
  setCampaigns: (campaigns: ICampaign[]) => void;
  selectedCampaigns: string[];
  setSelectedCampaigns: (campaigns: string[]) => void;
  fetchCampaigns: (workspace_id: string) => void;
  getDefaultCampaign: () => ICampaign;
}

export const useCampaignsStore = create<ICampaignsStore>((set, get) => ({
  date: {
    label: ' All Time',
    value: '',
  },
  setDate: (date) => {
    set({ date });
  },
  totalResults: 0,
  setTotalResults: (totalResults) => {
    set({ totalResults });
  },
  page: 1,
  setPage: (page) => {
    set({ page });
  },
  lastPage: 1,
  setLastPage: (lastPage) => {
    set({ lastPage });
  },
  archive: false,
  setArchive: (archive) => {
    set({ archive });
  },
  search: '',
  setSearch: (search) => {
    set({ search });
  },
  campaigns: [],
  setCampaigns: (campaigns) => {
    set({ campaigns });
  },
  selectedCampaigns: [],
  setSelectedCampaigns: (campaigns) => {
    set({ selectedCampaigns: campaigns });
  },
  fetchCampaigns: (workspace_id: string) => {
    new CampaignsService().getAll(workspace_id).then((res) => {
      // console.log(res, 'all campaigns from campaign store');
      if (res.length > 0) {
        set({ campaigns: res });
      } else {
        set({ campaigns: [] });
      }
    });
  },
  getDefaultCampaign: () => {
    const { campaigns } = get();
    return campaigns.find((campaign: any) => campaign?.is_default) || campaigns[0];
  },
}));
