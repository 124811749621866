import { ActionIcon, Flex, Group, Text, Image, Tooltip } from '@mantine/core';
import CardTitleOnlyIcon from '../../../../assets/images/common//card-title-only-icon.svg';
import CardTitleDescriptionIcon from '../../../../assets/images/common/card-title-and-description-icon.svg';
import CardTitleOnlyActiveIcon from '../../../../assets/images/common//card-title-only-active-icon.svg';
import CardTitleDescriptionActiveIcon from '../../../../assets/images/common/card-title-and-description-active-icon.svg';
import { BIO_CARD_STYLES } from '@replug/utils';

export const BioStyle = ({
  onChange,
  activeStyle,
}: {
  onChange: (value: string) => void;
  activeStyle: string;
}) => {
  return (
    <>
      <Flex direction={'column'}>
        <Flex align={'center'} mb={'sm'}>
          <Text fw={500} fz={14} mb={4}>
            Style
          </Text>
        </Flex>
        <Group>
          <Tooltip label="Image and title only">
            <ActionIcon
              variant="subtle"
              color={
                activeStyle === BIO_CARD_STYLES.TOP_IMAGE_TITLE_DESCRIPTION ? 'primary.6' : 'gray.6'
              }
              onClick={() => {
                onChange(BIO_CARD_STYLES.TOP_IMAGE_TITLE);
              }}
            >
              <Image
                src={
                  activeStyle === BIO_CARD_STYLES.TOP_IMAGE_TITLE
                    ? CardTitleOnlyActiveIcon
                    : CardTitleOnlyIcon
                }
                alt="Card Title Only Icon"
                h={24}
                w={24}
              />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Image, title and description">
            <ActionIcon
              variant="subtle"
              color={
                activeStyle === BIO_CARD_STYLES.TOP_IMAGE_TITLE_DESCRIPTION ? 'primary.6' : 'gray.6'
              }
              mr={'xs'}
              onClick={() => {
                onChange(BIO_CARD_STYLES.TOP_IMAGE_TITLE_DESCRIPTION);
              }}
            >
              <Image
                src={
                  activeStyle === BIO_CARD_STYLES.TOP_IMAGE_TITLE_DESCRIPTION
                    ? CardTitleDescriptionActiveIcon
                    : CardTitleDescriptionIcon
                }
                alt="Card Title Only Icon"
                h={24}
                w={24}
              />
            </ActionIcon>
          </Tooltip>
        </Group>
      </Flex>
    </>
  );
};
