import { BioLinkBlockService } from '@/services/BioLinkBlockService';
import { PreviewService } from '@/services/LinkPreviewService';
import { useBioRSSBlockStore } from '@/stores/Bio/useBioRSSBlockStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { faArrowsRotate, faCircle, faRss } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Divider,
  Fieldset,
  Flex,
  Grid,
  Group,
  Loader,
  Slider,
  Switch,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { DEFAULT_RSS_BLOCK_VALUES } from '@replug/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BioStyle } from '../Themes/BioStyle';
import { preview } from 'vite';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { ensureHttps } from '@/utils/LinkUtility';

export const BioRssSection = ({ reload }: { reload: () => void }) => {
  const { id } = useParams();
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [previewLoader, setPreviewLoader] = useState(false);

  const [bioRSSBlock, setBioRSSBlock] = useBioRSSBlockStore((state) => [
    state.bioRSSBlock,
    state.setBioRSSBlock,
  ]);

  const [bioLink] = useBioLinkSetupStore((state) => [state.bioLink]);

  const [debounced] = useDebouncedValue(bioRSSBlock.url, 1000);

  const fetchRSSPosts = async () => {
    setPreviewLoader(true);

    await new PreviewService()
      .rss(debounced)
      .then((response) => {
        if (response.data.status && response.data.data) {
          console.log('response.data', response.data);
          setBioRSSBlock({
            ...bioRSSBlock,
            posts: response.data.data,
          });
        } else {
          notifications.show({
            color: 'red',
            message: `Please enter a valid RSS feed, or check the URL`,
          });
        }
      })
      .catch(() => {
        // setBioCardBlock({
        //   ...bioCardBlock,
        //   title: '',
        //   description: '',
        //   image: '',
        // });
      });
    setPreviewLoader(false);
  };

  // Handle add block

  const handleAddBlock = () => {
    if (!bioRSSBlock.url) {
      notifications.show({
        color: 'red',
        message: `Please add a URL for the RSS block`,
      });
      return;
    }

    if (!bioRSSBlock.title && bioRSSBlock.is_show_title_enabled) {
      notifications.show({
        color: 'red',
        message: `Please add a title for the RSS block`,
      });
      return;
    }

    if (bioRSSBlock.posts.length === 0) {
      notifications.show({
        color: 'red',
        message: `Please enter a valid RSS feed, or check the URL`,
      });
      return;
    }

    let payload = {
      ...bioRSSBlock,
      bio_link_id: id,
      workspace_id: defaultWorkspace?._id,
    };

    if (bioRSSBlock._id) {
      new BioLinkBlockService()
        .update(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            setBioRSSBlock(DEFAULT_RSS_BLOCK_VALUES);
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      payload.order = bioLink?.blocks.length + 1;

      if (!bioLink?._id) {
        notifications.show({
          color: 'red',
          message: 'Please first publish your bio link.',
        });
        return;
      }
      new BioLinkBlockService()
        .create(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            setBioRSSBlock(DEFAULT_RSS_BLOCK_VALUES);
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (
      debounced.length > 0 &&
      debounced.startsWith('http') &&
      bioRSSBlock.posts.length === 0 &&
      !previewLoader
    ) {
      fetchRSSPosts();
    }
  }, [debounced]);

  return (
    <>
      <Text ta="center" px={'md'} pb={'md'} fz={14}>
        Display your latest blog posts in a vertical grid. Each post is shown with the title, image,
        and a basic description. You can display up to 5 posts at one time!
      </Text>
      <Divider />
      <Box p="md">
        <Flex mb={'md'} direction={'column'}>
          <Flex
            align={'flex-end'}
            justify={'end'}
            style={{
              flexGrow: 1,
            }}
          >
            <Switch
              size="xs"
              label="Show title"
              checked={bioRSSBlock.is_show_title_enabled}
              onChange={(e) => {
                setBioRSSBlock({ ...bioRSSBlock, is_show_title_enabled: e.currentTarget.checked });
              }}
            />
          </Flex>
          <TextInput
            w={'100%'}
            label="Add a title"
            placeholder="Your title for the RSS block"
            error={bioRSSBlock?.title === '' && bioRSSBlock?.is_show_title_enabled}
            value={bioRSSBlock.title}
            onChange={(e) => {
              setBioRSSBlock({ ...bioRSSBlock, title: e.currentTarget.value });
            }}
          />
        </Flex>

        <Fieldset>
          <Flex align={'center'}>
            <TextInput
              style={{
                flexGrow: 1,
              }}
              mb={'md'}
              label="RSS URL"
              placeholder="Add your RSS feed URL i.e https://blog.contentstudio.io/feed"
              value={bioRSSBlock.url}
              onChange={(e) => {
                const value = e.currentTarget.value;
                if (value) {
                  setBioRSSBlock({ ...bioRSSBlock, url: ensureHttps(value) });
                } else {
                  setBioRSSBlock({ ...bioRSSBlock, url: e.currentTarget.value });
                }
              }}
              leftSection={
                <>
                  <FontAwesomeIcon icon={faRss} />
                </>
              }
              disabled={previewLoader}
              rightSection={previewLoader && <Loader size="xs" />}
            />
            <ActionIcon
              disabled={previewLoader}
              loading={previewLoader}
              onClick={fetchRSSPosts}
              mt={8}
              variant="outline"
              ml={'sm'}
            >
              <FontAwesomeIcon icon={faArrowsRotate} size="sm" />
            </ActionIcon>
          </Flex>

          <Grid>
            <Grid.Col span={4}>
              <Flex mt={'md'} direction={'column'} justify={'center'} mb="sm">
                <Text fw={500} fz={14} mb={'sm'}>
                  No. of posts to display
                </Text>
                <Slider
                  w={180}
                  color="primary"
                  min={1}
                  step={1}
                  max={10}
                  marks={
                    [
                      { value: 1, label: '1' },
                      { value: 3, label: '3' },
                      { value: 6, label: '6' },
                      { value: 9, label: '9' },
                    ] as any[]
                  }
                  defaultValue={bioRSSBlock.number_of_posts_to_show}
                  value={bioRSSBlock.number_of_posts_to_show}
                  onChange={(value) => {
                    setBioRSSBlock({ ...bioRSSBlock, number_of_posts_to_show: value });
                  }}
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={4}>
              <BioStyle
                onChange={(value: string) => {
                  setBioRSSBlock({ ...bioRSSBlock, card_style: value });
                }}
                activeStyle={bioRSSBlock.card_style}
              />
            </Grid.Col>
            {/* <Grid.Col span={4}>
              <BioAnimation />
            </Grid.Col> */}
          </Grid>
          <Switch
            mt="lg"
            size="xs"
            checked={bioRSSBlock.is_auto_fetch_enabled}
            onChange={(e) => {
              setBioRSSBlock({ ...bioRSSBlock, is_auto_fetch_enabled: e.currentTarget.checked });
            }}
            label="Automatically fetch RSS feed posts after every 30 minutes."
          />
        </Fieldset>

        <Divider my={'md'} />
        <Center>
          <Group>
            <Button onClick={handleAddBlock}>
              {bioRSSBlock._id ? 'Update Block' : 'Add Block'}
            </Button>
          </Group>
        </Center>
      </Box>
    </>
  );
};
