export const findCampaignType = (campaign: any) => {
  if (!campaign) {
    return '';
  }

  if (campaign.is_call_to_action_enabled && campaign.is_retargeting_enabled) {
    return 'Call-to-Action | Retargeting';
  }
  if (campaign.is_call_to_action_enabled) {
    return 'Call-to-Action';
  }

  if (campaign.is_custom_widget_enabled && campaign.is_retargeting_enabled) {
    return '3rd Party Widget | Retargeting';
  }
  if (campaign.is_custom_widget_enabled) {
    return '3rd Party Widget';
  }

  if (campaign.is_retargeting_enabled) {
    return 'Retargeting';
  }

  return 'Shortener';
};

export const getCampaignDisplayText = (campaign: any) => {
  if (!campaign) {
    return '';
  }

  const typesDisplayingNA = ['no_cta', 'retargeting', 'integration'];

  // Return 'N/A' for specific types, otherwise return the campaign type
  if (typesDisplayingNA.includes(campaign.type)) {
    return 'N/A';
  }
  // Capitalize the first letter of the campaign type
  return campaign.call_to_action.type;
};

export const getCTAType = (campaign: any) => {
  if (!campaign) {
    return '';
  }
  if (campaign.is_call_to_action_enabled) {
    return campaign.call_to_action.type || 'Button';
  }
  return 'N/A';
};

export const getCampaignMessage = (campaign: any) => {
  if (!campaign) {
    return '';
  }

  const excludedTypes = ['retargeting', 'integration', 'no_cta'];
  const excludedCtaType = 'social_modern';

  if (!excludedTypes.includes(campaign.type) && campaign.cta_type !== excludedCtaType) {
    return campaign.call_to_action.message_headline || '';
  }
  return 'N/A';
};
