import { usePlanStore } from "@/stores/usePlanStore";
import { useWorkspaceStore } from "@/stores/useWorkspaceStore";
import { getRelativeTime } from "@/utils/DateUtility";
import { Button, Flex } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export const BillingBanner = () => {

  const [plan, setUpgradeModalState] = usePlanStore((state) => [state.plan, state.setUpgradeModalState]);
  const navigate = useNavigate();

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const is_subscription_paused = plan?.is_subscription_paused && plan?.paused_from

  const isUserOnGracePeriod = () => {
    const cancellationEffectiveDate = defaultWorkspace?.organization?.cancellation_effective_at;
    if (cancellationEffectiveDate && cancellationEffectiveDate > new Date().toISOString() && defaultWorkspace?.organization?.on_grace_period) {
      return true;
    }
    return false;
  }

  const getCancellationEffectiveDate = () => {
    return new Date(defaultWorkspace?.organization?.cancellation_effective_at).toDateString();
  }

  const getPausedFromDate = () => {
    return new Date(plan?.paused_from || '').toDateString();
  }

  return (
    plan?.type_of === 'trial' ? (
      <Flex
        justify={'center'}
        align={'center'}
        style={{
          padding: '8px',
          fontSize: '15px',
          fontWeight: '500',
        }}
      >
        Your trial plan ends {getRelativeTime(plan?.trial_ends_at)}
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            setUpgradeModalState(true);
            navigate('/account/billing');
          }}
          style={{ marginLeft: '8px' }}
        >
          Upgrade Now
        </Button>
      </Flex>
    ) : isUserOnGracePeriod() ? (
      <Flex
        justify={'center'}
        align={'center'}
        style={{
          padding: '8px',
          fontSize: '15px',
          fontWeight: '500',
        }}
      >
        Your subscription has been cancelled. You will lose access to Replug on {getCancellationEffectiveDate()}.
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            setUpgradeModalState(true);
            navigate('/account/billing');
          }}
          style={{ marginLeft: '8px' }}
        >
          Renew Now
        </Button>
      </Flex>
    ) : is_subscription_paused ? (
      <Flex
        justify={'center'}
        align={'center'}
        style={{
          padding: '8px',
          fontSize: '15px',
          fontWeight: '500',
        }}
      >
            Your subscription has been paused. You will lose access to Replug on {getPausedFromDate()}.
        <Button
          variant="outline"
          size="xs"
          onClick={() => {
            navigate('/account/billing');
          }}
          style={{ marginLeft: '8px' }}
        >
          View Plan
        </Button>
      </Flex>
    ) : null
  );
}
