import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu } from '@mantine/core';
import { format, subDays, subHours, subMonths, subYears, startOfMonth, endOfMonth } from 'date-fns';
import { useState } from 'react';

interface IDateMenu {
  onChange: (value: { label: string; value: string }) => void;
  labelValue?: string;
}

export function DateMenu({ onChange, labelValue = 'All time' }: IDateMenu) {
  const [label, setLabel] = useState(labelValue);

  function formatDateRange(value: string) {
    let startDate, endDate;
    endDate = new Date(); // current date and time

    switch (value) {
      case 'last_24_hours':
        startDate = subHours(endDate, 24);
        break;
      case 'last_7_days':
        startDate = subDays(endDate, 7);
        break;
      case 'last_30_days':
        startDate = subMonths(endDate, 1);
        break;
      case 'last_90_days':
        startDate = subMonths(endDate, 3);
        break;
      case 'last_6_months':
        startDate = subMonths(endDate, 6);
        break;
      case 'last_1_year':
        startDate = subYears(endDate, 1);
        break;
      case 'this_month':
        startDate = startOfMonth(new Date());
        endDate = new Date();
        break;
      case 'last_month':
        startDate = startOfMonth(subMonths(new Date(), 1));
        endDate = endOfMonth(subMonths(new Date(), 1));
        break;
      default:
        return ''; // 'All time' could be represented by the Unix Epoch (1970-01-01)
    }
    // return {
    //   fromDate: format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
    //   toDate: format(endDate, "yyyy-MM-dd'T'HH:mm:ss"),
    // };

    return `${format(startDate, "yyyy-MM-dd'T'HH:mm:ss")}_${format(
      endDate,
      "yyyy-MM-dd'T'HH:mm:ss"
    )}`;
  }
  return (
    <>
      <Menu>
        <Menu.Target>
          <Button
            variant="outline"
            color="gray"
            size="xs"
            rightSection={<FontAwesomeIcon icon={faChevronDown} />}
          >
            {label}
          </Button>
        </Menu.Target>
        <Menu.Dropdown>
          {[
            {
              label: 'All time',
              value: '',
            },
            {
              label: 'Last 24 hours',
              value: formatDateRange('last_24_hours'),
            },
            {
              label: 'Last 7 days',
              value: formatDateRange('last_7_days'),
            },
            {
              label: 'Last 30 days',
              value: formatDateRange('last_30_days'),
            },
            {
              label: 'Last 90 days',
              value: formatDateRange('last_90_days'),
            },
            {
              label: 'Last 6 months',
              value: formatDateRange('last_6_months'),
            },
            {
              label: 'Last 1 year',
              value: formatDateRange('last_1_year'),
            },
            {
              label: 'This month',
              value: formatDateRange('this_month'),
            },
            {
              label: 'Last month',
              value: formatDateRange('last_month'),
            },
          ].map((item) => (
            <Menu.Item
              key={item.label}
              onClick={() => {
                setLabel(item.label);
                onChange(item);
              }}
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu.Dropdown>
      </Menu>
    </>
  );
}
