import { LoaderDataTable } from '@/components/Menus/LoaderDataTable/LoaderDataTable';
import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { CustomDomainModal } from '@/components/Modals/CustomDomainModal/CustomDomainModal';
import { CustomDomainService } from '@/services/CustomDomainService';
import { useCustomDomainsStore } from '@/stores/useCustomDomainsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IDomain } from '@/types/domain';
import { getRelativeTime } from '@/utils/DateUtility';
import {
  faCheckCircle,
  faExclamation,
  faExclamationCircle,
  faGlobe,
  faPencil,
  faPlug,
  faTrash,
  faWarning,
  faCircleInfo
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Alert,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  Space,
  Switch,
  Table,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useDocumentTitle } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { modals } from '@mantine/modals';
import { DNSRecordsModal } from '@/components/Modals/DNSRecordsModal/DNSRecordsModal';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';
import { UpgradePlanMessage } from '@/components/Billing/UpgradePlanMessage';
import { usePlanStore } from '@/stores/usePlanStore';
import { SHORTENER_CUSTOM_DOMAIN } from '@/constants/Constants';

const DELETE_WORKSPACE_OPTIONS = {
  ONLY_THIS_WORKSPACE: 'workspace',
  ALL_WORKSPACES: 'all_workspaces',
};

export function CustomDomainsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Custom Domains | ' + defaultWorkspace?.agency?.name);

  const [plan] = usePlanStore((state) => [state.plan]);
  const [fetchCampaigns] = useCampaignsStore((state) => [state.fetchCampaigns]);
  const [opened, setOpened] = useState(false);
  const [checkConnectivityFlag, setCheckConnectivityFlag] = useState(false);
  const [dnsModalOpened, setDnsModalOpened] = useState(false);
  const [records, setRecords] = useState<any>([]);
  const [list, setList, loading, fetchCustomDomains] = useCustomDomainsStore((state) => [
    state.customDomains,
    state.setCustomDomains,
    state.loading,
    state.fetchCustomDomains,
  ]);
  const [editDomain, setEditDomain] = useState<IDomain | null>(null);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);

  const handleCheckConnectivity = async (domain: IDomain) => {
    setCheckConnectivityFlag(true)
    await new CustomDomainService()
      .checkConnectivity(defaultWorkspace?._id || '', domain.url, domain?._id || '')
      .then((res) => {
        if (res.data.status) {
          setCheckConnectivityFlag(false)
          const newList = list.map((item: IDomain) => {
            if (item?._id === domain?._id) {
              item.is_connected = res.data.data;
            }
            return item;
          });

          setList(newList);

          notifications.show({
            color: res.data.data ? 'green' : 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // Delete custom domain

  const handleDeleteCustomDomain = async (
    domain: IDomain,
    type: 'workspace' | 'all_workspaces' | string = 'workspace'
  ) => {
    await new CustomDomainService()
      .delete(defaultWorkspace?._id || '', domain._id, type)
      .then((res) => {
        if (res.data.status) {
          const newList = list.filter((item: IDomain) => item._id !== domain._id);
          setList(newList);
          fetchCampaigns(defaultWorkspace?._id || '');
          modals.closeAll();
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDNSRecords = async (domain: IDomain) => {
    await new CustomDomainService()
      .dnsRecords(defaultWorkspace?._id || '', domain._id)
      .then((res) => {
        if (res.data.status) {
          setRecords(res.data.data);
          setDnsModalOpened(true);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleStatueChange = async (domain: IDomain, status: any) => {
    await new CustomDomainService()
      .updateStatus(defaultWorkspace?._id || '', domain?._id, status)
      .then((res) => {
        if (res.data.status) {
          notifications.show({
            color: 'green',
            message: res.data.message,
          });
          fetchCustomDomains(defaultWorkspace?._id || '');
        }
      })
      .catch((err) => {
        console.error(err);
        if (err.response.status === 422 || err.response.status === 400 || err.response.status === 404) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
  };

  useEffect(() => {
    fetchCustomDomains(defaultWorkspace?._id || '');
  }, []);

  return (
    <>
      <DNSRecordsModal opened={dnsModalOpened} setOpened={setDnsModalOpened} records={records} />
      <CustomDomainModal opened={opened} setOpened={setOpened} domain={editDomain} />
      {!plan?.limits?.custom_domains ? (
        <UpgradePlanMessage />
      ) : (
        <Box
          style={{
            backgroundColor: 'var(--mantine-color-white)',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
            height: '100%',
            flexGrow: 1,
          }}
          ml={16}
          mr={16}
        >
          <Grid p={16}>
            <Grid.Col span={5}>
              {(defaultPermissions && checkPermission(defaultPermissions, ['add_cDomains'])) && <Button
                size="xs"
                onClick={() => {
                  setEditDomain(null);
                  setOpened(true);
                }}
              >
                Add Domain
              </Button>}
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex align={'center'} pl={'lg'} pt={'xs'}>
                <Text size="xl" fw="bold" mr="sm">
                  Custom Domains
                </Text>
                <Tooltip
                  position="bottom"
                  multiline
                  w={450}
                  fz="xs"
                  label="Custom Domains enable you to use your own branded domains for short links, enhancing brand visibility and trust."
                >
                  <FontAwesomeIcon
                    style={{
                      color: 'var(--mantine-color-gray-6)',
                    }}
                    icon={faCircleInfo}
                  />
                </Tooltip>
              </Flex>
            </Grid.Col>
          </Grid>
          <Divider color="gray.2" />
          <Table stickyHeader verticalSpacing={'md'}>
            <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
              <Table.Tr>
                <Table.Th ta="center">Connectivity</Table.Th>
                {(defaultPermissions && checkPermission(defaultPermissions, ['edit_cDomains'])) && <Table.Th ta="center">Set as Default</Table.Th>}

                <Table.Th w={'20%'}>URL</Table.Th>
                <Table.Th>404 Redirect URL</Table.Th>
                <Table.Th>Branded Domain URL</Table.Th>
                <Table.Th ta="center">Created at</Table.Th>
                <Table.Th ta={'center'}>Actions</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {loading ? (
                <>
                  <LoaderDataTable colSpan={7} />
                </>
              ) : (
                <>
                  {list.length === 0 && (
                    <>
                      <NoResultsDataTable
                        colSpan={7}
                        data={[]}
                        noResultsMessage="You do not have any custom domains created."
                      />
                    </>
                  )}
                  {list.map((item, index) => (
                    <>
                      <Table.Tr key={index}>
                        <Table.Td ta="center">
                          {item.is_connected ? (
                            <Tooltip label="Domain is connected">
                              <FontAwesomeIcon
                                style={{
                                  color: 'var(--mantine-color-green-6)',
                                }}
                                icon={faCheckCircle}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              multiline
                              w={250}
                              label="Domain is not connected, please follow the instructions to point custom domain."
                            >
                              <FontAwesomeIcon
                                style={{
                                  color: 'var(--mantine-color-red-6)',
                                }}
                                icon={faWarning}
                              />
                            </Tooltip>
                          )}
                        </Table.Td>
                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_cDomains'])) && <Table.Td ta="center">
                          <Center >
                            <Switch
                              size="xs"
                              checked={item.is_default}
                              onChange={(event) => handleStatueChange(item, event.currentTarget.checked)}
                              styles={{
                                track: {
                                  cursor: 'pointer',
                                },
                              }}
                            />
                          </Center>
                        </Table.Td>}
                        <Table.Td>
                          <Text fz={13}>{item.url}</Text>
                        </Table.Td>
                        <Table.Td>
                          <Text fz={13}>{item['404_redirect_url'] || 'N/A'}</Text>
                        </Table.Td>
                        <Table.Td>
                          <Text fz={13}>{item['branded_domain_url'] || 'N/A'}</Text>
                        </Table.Td>

                        <Table.Td ta="center">
                          <Text fz={13}>{getRelativeTime(item.created_at)}</Text>
                        </Table.Td>

                        <Table.Td ta="center">
                          <Flex align={'center'} justify={'center'}>
                            <Tooltip label="Check connectivity">
                              <ActionIcon
                                variant="subtle"
                                color="gray"
                                onClick={() => handleCheckConnectivity(item)}
                                disabled={checkConnectivityFlag}
                              >
                                <FontAwesomeIcon icon={faPlug} />
                              </ActionIcon>
                            </Tooltip>
                            <Space w={8} />
                            <Tooltip label="DNS records">
                              <ActionIcon
                                variant="subtle"
                                color="gray"
                                onClick={() => handleDNSRecords(item)}
                              >
                                <FontAwesomeIcon icon={faGlobe} />
                              </ActionIcon>
                            </Tooltip>
                            <Space w={8} />
                            {(defaultPermissions && checkPermission(defaultPermissions, ['edit_cDomains'])) && <Tooltip
                              label="Edit"
                              onClick={() => {
                                setEditDomain(item);
                                setOpened(true);
                              }}
                            >
                              <ActionIcon variant="subtle" color="gray">
                                <FontAwesomeIcon icon={faPencil} />
                              </ActionIcon>
                            </Tooltip>}
                            <Space w={8} />
                            {(defaultPermissions && checkPermission(defaultPermissions, ['delete_cDomains'])) && <Tooltip label="Delete">
                              <ActionIcon
                                variant="subtle"
                                color="red"
                                onClick={() => {
                                  modals.open({
                                    size: 'lg',
                                    title: <Title order={5}>Delete Custom Domain</Title>,
                                    children: (
                                      <>
                                        <Text mb={16} fz={14}>
                                          Are you sure you want to delete this custom domain? This
                                          action cannot be undone.
                                        </Text>
                                        <Alert
                                          mb={16}
                                          color="red"
                                          title="Warning"
                                          style={{
                                            color: 'var(--mantine-color-red-6)',
                                          }}
                                          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                                        >
                                          <Text fz={14} fw={500}>
                                            Your campaigns using this domain will be affected. Your links will be reverted to the default short domain and some of them may not work properly if the same slug (unique identifier) is already used by other users.
                                          </Text>
                                        </Alert>
                                        <Flex justify={'center'}>
                                          <Button
                                            variant="outline"
                                            color="red"
                                            mr={12}
                                            onClick={() =>
                                              handleDeleteCustomDomain(
                                                item,
                                                DELETE_WORKSPACE_OPTIONS.ONLY_THIS_WORKSPACE
                                              )
                                            }
                                          >
                                            Delete from this workspace
                                          </Button>
                                          {user?.migrated_to_v2 && <Button
                                            color="red"
                                            onClick={() =>
                                              handleDeleteCustomDomain(
                                                item,
                                                DELETE_WORKSPACE_OPTIONS.ALL_WORKSPACES
                                              )
                                            }
                                          >
                                            Delete from all workspaces
                                          </Button>}
                                        </Flex>
                                      </>
                                    ),
                                  });
                                }}
                              >
                                <FontAwesomeIcon icon={faTrash} />
                              </ActionIcon>
                            </Tooltip>}
                          </Flex>
                        </Table.Td>
                      </Table.Tr>
                    </>
                  ))}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Box>
      )}
    </>
  );
}
