import { WorkspaceLogoUpload } from '@/components/ImageUploaders/WorkspaceLogoUpload';
import { WorkspaceService } from '@/services/WorkspaceService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { ensureHttps } from '@/utils/LinkUtility';
import { Box, Button, Center, Divider, Flex, Grid, TextInput, Title } from '@mantine/core';
import { useForm, zodResolver } from '@mantine/form';
import { useDocumentTitle } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { z } from 'zod';

const schema = z.object({
  _id: z.string().min(1),
  name: z.string().min(2, { message: 'Name should have at least 2 letters' }),
  url: z.string().url({ message: 'Invalid URL' }).min(2, { message: 'Workspace URL is required.' }),
  logo: z.nullable(z.string()),
});

export function BasicSettingsPage() {

  const [workspaces, setWorkspaces, defaultWorkspace, setDefaultWorkspace] = useWorkspaceStore(
    (state) => [
      state.workspaces,
      state.setWorkspaces,
      state.defaultWorkspace,
      state.setDefaultWorkspace,
    ]
  );

  useDocumentTitle('Workspace Basic Settings | ' + defaultWorkspace?.agency?.name);

  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      _id: defaultWorkspace?._id,
      name: defaultWorkspace?.name,
      url: defaultWorkspace?.url,
      logo: defaultWorkspace?.logo,
    },

    validate: zodResolver(schema),

    transformValues: (values) => ({
      ...values,
      url: ensureHttps(values?.url || ""),
    }),
  });

  const handleUpdate = async (formValues: any) => {
    console.log("handleUpdate");
    setLoading(true);
    await new WorkspaceService()
      .update(defaultWorkspace?._id || '',formValues)
      .then((res) => {
        if (res.data.status) {
          // Modify the object
          const item = res.data.data;
          console.log(item);
          const modifiedWorkspace = {
            ...item.workspace,
            is_default: item.is_default,
            role: item.role,
          };

          setDefaultWorkspace(modifiedWorkspace);
          const modifiedWorkspaces = workspaces.map((wsItem: any) => {
            if (wsItem._id === modifiedWorkspace._id) {
              return modifiedWorkspace;
            }
            return wsItem;
          });
          setWorkspaces(modifiedWorkspaces);
          notifications.show({
            color: 'green',
            message: 'Workspace updated successfully.',
          });
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        if ((err.response.status === 400 ||  err.response.status ===  422) && err?.response?.data?.message) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
    setLoading(false);
  };

  useEffect(() => {
    if (defaultWorkspace) {
      form.setValues({
        _id: defaultWorkspace?._id,
        name: defaultWorkspace?.name,
        url: defaultWorkspace?.url,
        logo: defaultWorkspace?.logo,
      });
    }
  }, [defaultWorkspace]);

  return (
    <Box
      style={{
        backgroundColor: 'var(--mantine-color-white)',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
        height: '100%',
        flexGrow: 1,
      }}
      ml={16}
      mr={16}
    >
      <Box p={16}>
        <Center>
          <Title order={5}>Basic Settings</Title>
        </Center>
      </Box>
      <Divider color="gray.2" />

      <Grid w={800} style={{ margin: '0 auto' }} mt={32}>
        <Grid.Col span={5}>
          <WorkspaceLogoUpload form={form} />
        </Grid.Col>
        <Grid.Col span={7}>
          <Box mr={16}>
            <form onSubmit={form.onSubmit(handleUpdate)}>
              <TextInput
                mb={12}
                label="Workspace Name"
                placeholder="e.g Company Name"
                variant="filled"
                {...form.getInputProps('name')}
              />
              <TextInput
                mb={12}
                label="URL"
                description="Workspace URL used for the branded call-to-actions."
                placeholder="e.g https://replug.io"
                variant="filled"
                value={form.values.url}
                {...form.getInputProps('url')}
              />
              <Flex pb={'sm'} pt={'sm'}>
                <Button disabled={loading} loading={loading} fullWidth type="submit">
                  Save
                </Button>
              </Flex>
            </form>
          </Box>
        </Grid.Col>
      </Grid>
    </Box>
  );
}
