import { CAMPAIGN_OPTIONS } from '@/constants/Constants';
import { IntegrationServie } from '@/services/IntegrationService';
import { UtilsService } from '@/services/UtilsService';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
  faFile,
  faImage,
  faInfoCircle,
  faLineColumns,
  faLink,
  faTimes,
  faUpload,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Center,
  Checkbox,
  Divider,
  Flex,
  Group,
  InputLabel,
  Loader,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Title,
  Tooltip,
  rem,
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import { CallToActionTypeEnum, CallToActionVariantsEnum } from '@replug/utils';
import { useEffect, useState } from 'react';
import { CTAAgreementListing } from './CTAAgreement/CTAAgreementListing';
// import { CAMPAIGN_OPTIONS, CallToActionTypeEnum } from '@/types/campaigns';

export const CallToActionType = ({
  nextStep,
  form,
  integrationsList,
}: {
  nextStep: () => void;
  form: any;
  integrationsList: any;
}) => {
  const [loadingImage, setLoadingImage] = useState(false);

  const [exitIntentImageLoader, setExitIntentImageLoader] = useState(false);

  const [loadingLists, setLoadingLists] = useState(false);

  const [integrationAccounts, setIntegrationAccounts] = useState<any>([]);

  const [integrationLists, setIntegrationLists] = useState<any>([]);

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const handleFileDrop = async (files: any, type: string = 'call_to_action_image') => {
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('input_file', files[0]);
      if (type === 'background_image_exit_intent') {
        setExitIntentImageLoader(true);
      } else {
        setLoadingImage(true);
      }

      await new UtilsService()
        .uploadImage(formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            if (type === 'call_to_action_image') {
              form.setFieldValue('call_to_action.image', res.data.url);
            } else {
              form.setFieldValue('call_to_action.background_image_exit_intent', res.data.url);
            }
          }
        })
        .catch((err) => {
          if ((err.response.status === 400 ||  err.response.status ===  422) && err?.response?.data?.message) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });

      if (type === 'background_image_exit_intent') {
        setExitIntentImageLoader(false);
      } else {
        setLoadingImage(false);
      }

      // You can now use formData to send the file data to your server or perform any other actions.
    }
  };

  const handleIntegrationChange = async (value: string | null) => {
    form.setFieldValue('call_to_action.email_service', value);
    form.setFieldValue('call_to_action.email_service_id', null);
    form.setFieldValue('call_to_action.email_list', null);
    setIntegrationAccounts([]);
    setIntegrationLists([]);

    if (!value) {
      setIntegrationAccounts([]);
      return;
    }
    await fetchIntegrationAccounts(value);
  };

  const fetchIntegrationAccounts = async (integration_name: string) => {
    await new IntegrationServie()
      .getIntegrations(defaultWorkspace?._id || '', integration_name)
      .then((res) => {
        if (res.data.status) {
          setIntegrationAccounts(res.data?.data || []);
        } else {
          notifications.show({
            color: 'red',
            message: res.data?.message || 'Error fetching integrations',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        notifications.show({
          color: 'red',
          message: err.response?.data?.message || 'Error fetching integrations',
        });
      });
  };

  const handleAccountChange = async (value: string | null) => {
    form.setFieldValue('call_to_action.email_service_id', value);
    form.setFieldValue('call_to_action.email_list', null);
    if (!value) {
      setIntegrationLists([]);
      return;
    }

    // Gist doesn't have lists
    if (form.values.call_to_action.email_service === 'gist') {
      form.setFieldValue('call_to_action.email_list', "gist");
      return;
    }

    await fetchIntegrationLists(value);
  };

  const fetchIntegrationLists = async (integration_id: string) => {
    setLoadingLists(true);
    await new IntegrationServie()
      .getLists(defaultWorkspace?._id || '', integration_id)
      .then((res) => {
        if (res.data.status) {
          setIntegrationLists(res.data?.data || []);
        } else {
          notifications.show({
            color: 'red',
            message: res.data?.message || 'Error fetching lists',
          });
        }
      })
      .catch((err) => {
        console.error(err);
        notifications.show({
          color: 'red',
          message: err.response?.data?.message || 'Error fetching lists',
        });
      });
    setLoadingLists(false);
  };

  useEffect(() => {
    if (form.values.call_to_action.email_service) {
      fetchIntegrationAccounts(form.values.call_to_action.email_service);
    }
    if (form.values.call_to_action.email_service_id) {
      fetchIntegrationLists(form.values.call_to_action.email_service_id);
    }
  }, []);

  return (
    <>
      {' '}
      <Title order={5} px={24} py={16}>
        Call-to-action (CTA)
      </Title>
      <Divider mb={12} color="gray.2" />
      <InputLabel mx={24}>Type</InputLabel>
      <SegmentedControl
        fullWidth
        mx={24}
        {...form.getInputProps('call_to_action.type')}
        data={[
          {
            label: (
              <>
                <Center style={{ gap: 10 }}>
                  <FontAwesomeIcon color="gray.7" size="sm" icon={faLineColumns} />
                  <Text fz="sm">Button</Text>
                </Center>
              </>
            ),
            value: CallToActionTypeEnum.Button,
          },
          {
            label: (
              <>
                <Center style={{ gap: 10 }}>
                  <FontAwesomeIcon color="gray.7" size="sm" icon={faLink} />
                  <Text fz="sm">Link</Text>
                </Center>
              </>
            ),
            value: CallToActionTypeEnum.Link,
          },
          {
            label: (
              <>
                <Center style={{ gap: 10 }}>
                  <FontAwesomeIcon color="gray.7" size="sm" icon={faFile} />
                  <Text fz="sm">Form</Text>
                </Center>
              </>
            ),
            value: CallToActionTypeEnum.Form,
          },
        ]}
        onChange={(newValue) => {
          form.setFieldValue('call_to_action.type', newValue);
          if (newValue === CallToActionTypeEnum.Button) {
            form.setFieldError('call_to_action.button_url', null); // Clear button_url error if any
          }
        }}
      />
      <Stack pt={8} px={24}>
        <Select
          {...form.getInputProps('call_to_action.variant')}
          label="Style"
          placeholder="Campaign style i.e Social, Banner, Popup etc."
          variant="filled"
          data={CAMPAIGN_OPTIONS}
        />
        <InputLabel>
          CTA Image{' '}
          <Tooltip label="You can change a call to action image by uploading your image.  ">
            <FontAwesomeIcon
              style={{
                marginTop: 4,
                marginLeft: 4,
              }}
              icon={faInfoCircle}
            />
          </Tooltip>
        </InputLabel>
        <Dropzone
          onDrop={(files) => handleFileDrop(files)}
          maxFiles={1}
          onReject={(files) => console.log('rejected files', files)}
          maxSize={5 * 1024 ** 2}
          accept={IMAGE_MIME_TYPE}
        >
          <Group justify="center" gap="xl" mih={40} style={{ pointerEvents: 'none' }}>
            <Dropzone.Accept>
              <FontAwesomeIcon
                color="gray.7"
                size="sm"
                icon={faUpload}
                style={{ width: rem(32), height: rem(32), color: 'var(--mantine-color-blue-6)' }}
              />
            </Dropzone.Accept>
            <Dropzone.Reject>
              <FontAwesomeIcon
                color="gray.7"
                size="sm"
                icon={faTimes}
                style={{ width: rem(32), height: rem(32), color: 'var(--mantine-color-red-6)' }}
              />
            </Dropzone.Reject>
            <Dropzone.Idle>
              <FontAwesomeIcon
                color="gray.7"
                size="sm"
                icon={faImage}
                style={{ width: rem(32), height: rem(32), color: 'var(--mantine-color-gray-4)' }}
              />
            </Dropzone.Idle>

            <div>
              <Text size="sm" inline>
                {form.values.call_to_action.image
                  ? 'Drag or click to replace image.'
                  : 'Drag image here or click to select file.'}
              </Text>
            </div>
          </Group>
          {[
            CallToActionVariantsEnum.SocialClassic,
            CallToActionVariantsEnum.SocialModern,
            CallToActionVariantsEnum.Banner,
            CallToActionVariantsEnum.ScrollBox,
          ].includes(form.values.call_to_action.variant) && (
            <>
              <Text fz="11" ta="center" my={8}>
                Recommended Size 64 x 64 (px)
              </Text>
            </>
          )}
          {[
            CallToActionVariantsEnum.ExitIntent,
            CallToActionVariantsEnum.Popup,
            CallToActionVariantsEnum.BridgePage,
          ].includes(form.values.call_to_action.variant) && (
            <>
              <Text fz="11" ta="center" my={8}>
                Recommended Size 920 x 1080 (px)
              </Text>
            </>
          )}
          {loadingImage && (
            <Center>
              <Flex align={'center'}>
                <Loader size={'xs'} mr={8} />
                <Text fz={10} ta="center" pt="sm">
                  Uploading Image...
                </Text>
              </Flex>
            </Center>
          )}
        </Dropzone>
        {form.values.call_to_action.image && (
          <Center>
            <Button
              variant="subtle"
              color="red"
              size="xs"
              onClick={() => {
                form.setFieldValue('call_to_action.image', '');
              }}
            >
              Remove Image
            </Button>
          </Center>
        )}

        {(form.values.call_to_action.variant === CallToActionVariantsEnum.ExitIntent || form.values.call_to_action.variant === CallToActionVariantsEnum.BridgePage) && (
          <>
            <InputLabel>
              Exit Intent Background Image{' '}
              <Tooltip label="You can change a background image for Exit Intent Popup.">
                <FontAwesomeIcon
                  style={{
                    marginTop: 4,
                    marginLeft: 4,
                  }}
                  icon={faInfoCircle}
                />
              </Tooltip>
            </InputLabel>
            <Dropzone
              onDrop={(files) => handleFileDrop(files, 'background_image_exit_intent')}
              onReject={(files) => console.log('rejected files', files)}
              maxSize={5 * 1024 ** 2}
              accept={IMAGE_MIME_TYPE}
            >
              <Group justify="center" gap="xl" mih={40} style={{ pointerEvents: 'none' }}>
                <Dropzone.Accept>
                  <FontAwesomeIcon
                    color="gray.7"
                    size="sm"
                    icon={faUpload}
                    style={{
                      width: rem(32),
                      height: rem(32),
                      color: 'var(--mantine-color-blue-6)',
                    }}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <FontAwesomeIcon
                    color="gray.7"
                    size="sm"
                    icon={faTimes}
                    style={{ width: rem(32), height: rem(32), color: 'var(--mantine-color-red-6)' }}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <FontAwesomeIcon
                    color="gray.7"
                    size="sm"
                    icon={faImage}
                    style={{
                      width: rem(32),
                      height: rem(32),
                      color: 'var(--mantine-color-gray-4)',
                    }}
                  />
                </Dropzone.Idle>

                <div>
                  <Text size="sm" inline>
                    {form.values.call_to_action.background_image_exit_intent
                      ? 'Drag or click to replace image.'
                      : 'Drag image here or click to select file.'}
                  </Text>
                </div>
              </Group>
              <Text fz="11" ta="center" my={8}>
                Recommended Size 920 x 1080 (px)
              </Text>
              {exitIntentImageLoader && (
                <Center>
                  <Flex align={'center'}>
                    <Loader size={'xs'} mr={8} />
                    <Text fz={10} ta="center" pt="sm">
                      Uploading Image...
                    </Text>
                  </Flex>
                </Center>
              )}
            </Dropzone>
          </>
        )}

        {form.values.call_to_action.background_image_exit_intent &&
          (form.values.call_to_action.variant === CallToActionVariantsEnum.ExitIntent || form.values.call_to_action.variant === CallToActionVariantsEnum.BridgePage) && (
            <Center>
              <Button
                variant="subtle"
                color="red"
                size="xs"
                onClick={() => {
                  form.setFieldValue('call_to_action.background_image_exit_intent', '');
                }}
              >
                Remove Image
              </Button>
            </Center>
          )}

        {form.values.call_to_action.type == CallToActionTypeEnum.Form && (
          <>
            {/* integrations list */}
            <Select
              autoComplete="off"
              autoCorrect="off"
              label="Email Service Provider"
              value={form.values.call_to_action.email_service}
              searchable
              data={integrationsList.map((integration: any) => ({
                label: integration.label,
                value: integration.name,
                disabled: !integration.is_connected,
              }))}
              placeholder="Select your email service provider"
              variant="filled"
              onChange={handleIntegrationChange}
            />

            {/* integration accounts list */}
            {integrationAccounts.length > 0 && (
              <Select
                label="Select an account"
                value={form.values.call_to_action.email_service_id}
                searchable
                data={integrationAccounts.map((integration: any) => ({
                  label: integration.name,
                  value: String(integration.account_id),
                }))}
                placeholder="Select an account"
                variant="filled"
                onChange={handleAccountChange}
              />
            )}

            {/* lists loader */}
            {loadingLists && (
              <Center>
                <Loader size={'sm'} />
              </Center>
            )}

            {/* integration lists */}
            {integrationLists.length > 0 && (
              <Select
                label="Select your email list"
                value={form.values.call_to_action.email_list}
                searchable
                data={integrationLists.map((integration: any) => ({
                  label: integration.name,
                  value: integration.id?.toString(),
                }))}
                placeholder="Select your email list"
                variant="filled"
                {...form.getInputProps('call_to_action.email_list')}
              />
            )}
          </>
        )}

        <CTAAgreementListing form={form} />

        {form.values.call_to_action.type == CallToActionTypeEnum.Form && (
          <Checkbox
            label="Enable Name Field"
            checked={form.values.call_to_action.name_field}
            {...form.getInputProps('call_to_action.name_field')}
          />
        )}

        <Center>
          <Button
            w={'200'}
            onClick={() => {
              if (form.values.call_to_action.type === CallToActionTypeEnum.Form) {
                if (!form.values.call_to_action.email_service) {
                  notifications.show({
                    color: 'red',
                    message: 'Please select an email service provider',
                  });
                  return;
                }
                if (!form.values.call_to_action.email_list) {
                  notifications.show({
                    color: 'red',
                    message: 'Please select an email list',
                  });
                  return;
                }
              }
              nextStep();
            }}
          >
            Next
          </Button>
        </Center>
      </Stack>
    </>
  );
};
