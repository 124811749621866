import { APP_ENVIRONMENT } from '@/constants/Constants';
import ReplugLogo from '../assets/images/logos/replug-logo.svg';

export const checkPermission = (permissions: string|any, check_permission: string[]|any, invert: boolean = true) => {
    //@ts-ignore
    return invert ? (check_permission.some(permission => 
      //@ts-ignore
      permissions.some(item => item?.name === permission)
    )) : 
    //@ts-ignore
    !(check_permission.some(permission => 
      //@ts-ignore
      permissions.some(item => item?.name === permission)
    ))
};

export const isUserCollaborator = (user: string[]|any, workspace_id: string|any, payload: any, type: string) => {
  //@ts-ignore
  const workspace_member = user.workspace_member.find(member => member.workspace_id === workspace_id);
  if (workspace_member?.role === 'collaborator') {
    switch (type) {
      case "campaign":
      case "link":
      case "rss":
      case "bio_link":
      case "tag":
      case "pixel":
      case "widget":
      case "utm":
      case "exclude_traffic":
      case "IpAddress":
          if (workspace_member.user_id !== payload.user_id) {
            // console.log('is user collaborator payload: ', payload);
            return false
          }
          break
      }
    }
    return true
};


/**
 * Get paddle plan id according to the environment
 * @param plan
 * @returns string
 */
export const getPaddlePlanId = (plan: any) => {
  switch (APP_ENVIRONMENT) {
    case 'dev':
      return plan.dev_paddle_id;
    case 'dev-qa':
      return plan.qa_paddle_id;
    case 'staging':
      return plan.stage_paddle_id;
    case 'production':
      return plan.prod_paddle_id;
    default:
      return plan.prod_paddle_id;
  }
};

/**
 * Check if the workspace is locked or not using the organization status
 * @param workspace 
 * @returns 
 */
export const isWorkspaceLocked = (workspace: any) => {
  return (workspace?.organization?.status && workspace.organization.status !== 'active') ? true : false;
};

/**
 * Get app logo according to the whitelabel status
 * @returns string
 */
export const getAppLogo = () => {
  if(!isWhitelabelDomain()) {
    return ReplugLogo;
  }
  const whitelabel = JSON?.parse(localStorage.getItem('replug-whitelable') || '{}');
  if (whitelabel?.is_whitelabeled && whitelabel?.logo) {
    return whitelabel.logo;
  }
  return ReplugLogo;
}

/**
 * Is whitelabel domain
 * @returns boolean
 */
export const isWhitelabelDomain = () => {
  return !window.location.hostname.includes('.replug.io') && !window.location.hostname.includes('localhost');
};

/**
 * Save whitelabel settings to local storage and update favicon and title
 * @param whitelabel
 * @param updateTitle
 * @returns void
 */
export const saveWhitelabelSettings = (whitelabel: any, updateTitle = false) => {
  localStorage.setItem("replug-whitelable", JSON.stringify(whitelabel));
  const link = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
  if (link) {
    link.href = whitelabel?.favicon;
  }
  if (updateTitle) {
    document.title = whitelabel?.name || 'Replug';
  }
}

/**
 * Format limit
 * @param limit 
 * @returns 
 */
export const formatLimit = (limit: number, limitType: string = '') => {
  if (limitType === 'clicks') {
    if (limit >= 10000000) {
      return 'Unlimited';
    }
    return limit;
  }
  if (limit >= 1000) {
    return 'Unlimited';
  }
  return limit;
};

/**
   * Get progress bar value
   * @param limitType
   * @returns
   */
export const getPlanLimitsProgressValue = (plan: any, limitType: string) => {
  // @ts-ignore
  if (plan?.limits?.[limitType] == 0) {
    return 100;
  }
  // @ts-ignore
  return (plan?.used_limits?.[limitType] ?? 0) / (plan?.limits?.[limitType] ?? 1) * 100;
};

/**
 * Get progress bar color
 * @param limitType
 */
export const getPlanLimitsProgressColor = (plan: any, limitType: string) => {
  return getPlanLimitsProgressValue(plan, limitType) > 75 ? 'red' : 'blue';
};
