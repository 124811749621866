import { Box, Container, ScrollArea, Space, Text } from '@mantine/core';
import classes from '../../BioLinks.module.css';
import { BioCard, BioLink, BioProfile, BioRSS, BioSocial, BioText, BioVideo } from '@replug/ui';
import { createContrastGradientBackground } from '@replug/utils';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { isList } from '@tiptap/react';

export const BioLinkPreviewContainer = ({
  values,
  blocks,
  isListing = false,
}: {
  values: any;
  blocks: any;
  isListing?: boolean;
}) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const getBlockComponent = (block: any, index: number) => {
    switch (block.type) {
      case 'text':
        return <BioText values={block} theme={values.theme} />;
      case 'social':
        return <BioSocial values={block} theme={values.theme} />;
      case 'rss':
        return <BioRSS values={block} theme={values.theme} />;
      case 'video':
        return <BioVideo values={block} theme={values.theme} />;
      case 'card':
        return <BioCard values={block} theme={values.theme} />;
      case 'links':
        return <BioLink values={block} theme={values.theme} />;
      default:
        return <Text>...</Text>;
    }
    return '';
  };
  return (
    <>
      <Box className={isListing ? classes.listPreviewContainer : classes.mobileContainer}>
        <Box
          className={isListing ? classes.listPreviewContainerInner : classes.mobileContainerInner}
          style={{
            background:
              values.theme?.background_style === 'gradient'
                ? `${createContrastGradientBackground(values.theme?.background_color)}`
                : values.theme?.background_color || '#fff',
          }}
        >
          <ScrollArea
            scrollbarSize={4}
            h={
              isListing && defaultWorkspace?.powered_by
                ? 400
                : isListing && !defaultWorkspace?.powered_by
                  ? 440
                  : !isListing && !defaultWorkspace
                    ? 653
                    : 623
            }
          >
            <Container>
              <BioProfile values={values} />
              {blocks.map((block: any, index: number) => (
                <>
                  {getBlockComponent(block, index)}
                  <Space h="xs" />
                </>
              ))}
            </Container>
          </ScrollArea>
          {defaultWorkspace?.powered_by && (
            <>
              <Box
                className={
                  isListing
                    ? classes.listPreviewContainerInnerFooter
                    : classes.mobileContainerInnerFooter
                }
              >
                {!defaultWorkspace?.agency?.is_whitelabel_enabled && <Text fz={11} color={'gray'}>Bio link created using <strong>{defaultWorkspace?.agency?.name}</strong>
                </Text>}
                
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
