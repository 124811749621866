import { Box, Button, Center, Flex, Image, Stack } from '@mantine/core';
import NeedHelp from '../../assets/images/common/need-help.svg';
import classes from './dashboard.module.css';

export function DashboardContact() {
  return (
    <>
      <Box h={200} p={16} className={classes.dottedBorder}>
        <Stack>
          <Center>
            <Image src={NeedHelp} h={100} mah={100} maw={198} w={198} fit="contain" />
          </Center>

          <Flex align={'center'} justify={'center'}>
            <Button color="gray.9" mr={8}>
              Chat with us
            </Button>
            <Button
              color="gray.9"
              variant="outline"
              onClick={() => {
                window.open('https://replug.io/book-a-demo', '_blank');
              }}
            >
              Book a demo
            </Button>
          </Flex>
        </Stack>
      </Box>
    </>
  );
}
