import { ColorPaellete } from '@/components/ColorPaellete/ColorPaellete';
import { COLOR_PAELLETES } from '@/constants/Constants';
import { useCampaignSetupStore } from '@/stores/useCampaignSetupStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
  Box,
  Button,
  Center,
  ColorInput,
  Divider,
  Fieldset,
  Flex,
  Group,
  NumberInput,
  SegmentedControl,
  Slider,
  Stack,
  Switch,
  Text,
  Title,
  Tooltip
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { CTAAppearanceEnum, CallToActionTypeEnum, CallToActionVariantsEnum } from '@replug/utils';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useCampaignsStore } from '@/stores/useCampaignsStore';

export const CallToActionCustomization = ({
  form,
  nextStep,
}: {
  form: any;
  nextStep: () => void;
}) => {
  const appearanceOptions = [
    {
      label: 'After X Seconds',
      value: CTAAppearanceEnum.TIME,
    },
    {
      label: 'Exit Intent',
      value: CTAAppearanceEnum.EXIT_INTENT,
    },
  ];
  const [storeCampaign, createLoading] = useCampaignSetupStore((state) => [
    state.storeCampaign,
    state.createLoading,
  ]);
  const [setPage] = useCampaignsStore((state) => [state.setPage]);

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const handleSetColors = (palette: any) => {
    form.setFieldValue('call_to_action.background_color', palette.backgroundColor);
    form.setFieldValue('call_to_action.text_color', palette.textColor);
    form.setFieldValue('call_to_action.button_background_color', palette.buttonBackgroundColor);
    form.setFieldValue('call_to_action.button_text_color', palette.buttonTextColor);
    form.setFieldValue('call_to_action.link_color', palette.buttonBackgroundColor);
  };

  const navigate = useNavigate();
  const handleSaveCampaign = async () => {
    if (!form.isValid()) {
      console.log(form.errors);
      const firstValue = Object.values(form.errors)[0];
      if (firstValue) {
        notifications.show({
          color: 'red',
          // @ts-ignore
          message: firstValue ? firstValue : 'Please fill all required fields',
        });
        return;
      }
    }

    if (
      form.values.is_call_to_action_enabled &&
      form.values.call_to_action.type !== CallToActionTypeEnum.Form &&
      !form.values.call_to_action.button_url
    ) {
      notifications.show({
        color: 'red',
        message: 'Please add call to action button URL.',
      });
      return;
    }

    if (form.values.call_to_action.type === CallToActionTypeEnum.Form) {
      if (!form.values.call_to_action.email_service_id) {
        notifications.show({
          color: 'red',
          message: 'Please select an email service',
        });
        return;
      }

      if (!form.values.call_to_action.email_list) {
        notifications.show({
          color: 'red',
          message: 'Please select an email list',
        });
        return;
      }
    }

    const res = await storeCampaign(defaultWorkspace?._id || '', form.values);
    if (res) {
      setPage(1)
      navigate(`/campaigns/${defaultWorkspace?._id}`);
    }
  };

  return (
    <>
      {' '}
      <Title order={5} px={24} py={16}>
        Customization
      </Title>
      <Divider color="gray.2" />
      {/* <form
        onSubmit={form.onSubmit(
          form.values.is_retargeting_enabled ? nextStep() : handleSaveCampaign()
        )}
      > */}
      <ColorPaellete p={24} handleSetColors={handleSetColors} />
      <Divider color="gray.4" px={24} label={'OR'} />
      <Stack p={24}>
        <Fieldset legend="Customize colors">
          <Group>
            <Flex>
              <ColorInput
                label="Background color"
                {...form.getInputProps('call_to_action.background_color')}
                mr={8}
              />
              <ColorInput
                ml={8}
                label="Text color"
                {...form.getInputProps('call_to_action.text_color')}
              />
            </Flex>
          </Group>
          <Group mt={16}>
            {form.values.call_to_action.type === CallToActionTypeEnum.Link ? (
              <>
                <ColorInput
                  label="Link text color"
                  {...form.getInputProps('call_to_action.link_color')}
                  mr={8}
                />
              </>
            ) : (
              <Flex>
                <ColorInput
                  label="Button background color"
                  {...form.getInputProps('call_to_action.button_background_color')}
                  mr={8}
                />
                <ColorInput
                  ml={8}
                  label="Button text color"
                  {...form.getInputProps('call_to_action.button_text_color')}
                />
              </Flex>
            )}
            {(form.values.call_to_action.variant === CallToActionVariantsEnum.ExitIntent || form.values.call_to_action.variant === CallToActionVariantsEnum.BridgePage) && (
              <Flex direction={'column'} mb={8}>
                <Text fz="sm" fw={500}>
                  {' '}
                  Exit Intent Opacity
                </Text>
                <Slider
                  w={180}
                  color="primary"
                  min={0}
                  step={0.1}
                  max={1}
                  marks={
                    [
                      { value: 0, label: '0' },
                      { value: 0.5, label: '0.5' },
                      { value: 1, label: '1' },
                    ] as any[]
                  }
                  defaultValue={1}
                  {...form.getInputProps('call_to_action.exit_intent_opacity')}
                />
              </Flex>
            )}
          </Group>
        </Fieldset>
        {CallToActionVariantsEnum.BridgePage !== form.values.call_to_action.variant && (
          <Fieldset legend="Appearance">
            <Flex direction={'column'}>
              <SegmentedControl
                {...form.getInputProps('call_to_action.trigger_type')}
                data={appearanceOptions}
              />
              {CTAAppearanceEnum.TIME === form.values.call_to_action.trigger_type && (
                <>
                  <NumberInput
                    w={200}
                    stepHoldDelay={500}
                    stepHoldInterval={100}
                    size="xs"
                    label="Seconds"
                    {...form.getInputProps('call_to_action.trigger_seconds')}
                  />
                </>
              )}
            </Flex>
          </Fieldset>
        )}

        {/* Bridge type CTA */}
        {CallToActionVariantsEnum.BridgePage === form.values.call_to_action.variant && (
          <Fieldset legend="Appearance">
            <Flex direction={'column'}>
              <SegmentedControl
                {...form.getInputProps('call_to_action.trigger_type')}
                data={[
                  {
                    label: 'Redirect after X Seconds',
                    value: CTAAppearanceEnum.TIME,
                  },
                ]}
              />
              {CTAAppearanceEnum.TIME === form.values.call_to_action.trigger_type && (
                <>
                  <NumberInput
                    w={200}
                    stepHoldDelay={500}
                    min={3}
                    stepHoldInterval={100}
                    max={30}
                    size="xs"
                    label="Seconds"
                    {...form.getInputProps('call_to_action.trigger_seconds')}
                  />
                </>
              )}
            </Flex>
          </Fieldset>
        )}
        {CallToActionVariantsEnum.BridgePage !== form.values.call_to_action.variant && (
          <>
            <Fieldset legend="Other Options">
              {!['exit_intent', 'popup', 'banner'].includes(form.values.call_to_action.variant) && (
                <>
                  <Group mb={16}>
                    <Text size="sm" fw={500}>
                      Position
                    </Text>
                    <SegmentedControl
                      {...form.getInputProps('call_to_action.position')}
                      data={[
                        {
                          label: 'Bottom Left',
                          value: 'left',
                        },
                        {
                          label: 'Bottom Right',
                          value: 'right',
                        },
                      ]}
                    />
                  </Group>
                </>
              )}

              <Group>
                <Text size="sm" fw={500}>
                  Popup sound
                </Text>
                <Switch
                  checked={
                    form.values.call_to_action.sound !== undefined
                      ? form.values.call_to_action.sound
                      : false
                  }
                  {...form.getInputProps('call_to_action.sound')}
                />
                <Tooltip
                  position="bottom"
                  multiline
                  w={300}
                  fz="xs"
                  label="This feature may not work on Chrome Browser."
                >
                  <FontAwesomeIcon
                    style={{
                      color: 'var(--mantine-color-gray-6)',
                    }}
                    icon={faCircleInfo}
                  />
                </Tooltip>
              </Group>
            </Fieldset>
          </>
        )}

        <Center>
          {form.values.is_retargeting_enabled ? (
            <>
              <Button w={'200'} onClick={() => nextStep()}>
                Next
              </Button>
            </>
          ) : (
            <>
              <Button
                loading={createLoading}
                disabled={createLoading}
                w={'200'}
                onClick={() => handleSaveCampaign()}
              >
                Save Campaign
              </Button>
            </>
          )}
        </Center>
      </Stack>
      {/* </form> */}
    </>
  );
};
