import { CampaignsColumnsMenu } from '@/components/Menus/CampaignsColumnsMenu/CampaignsColumnsMenu';
import { DateMenu } from '@/components/Menus/DateMenu/DateMenu';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import {
  faBoxArchive,
  faCircleExclamation,
  faEllipsisVertical,
  faLink,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Button,
  Flex,
  Grid,
  Menu,
  Space,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';
import { useEffect } from 'react';

export function CampaignsHeader() {
  const theme = useMantineTheme();
  const [archive, setArchive, search, setSearch, setDate, setPage] = useCampaignsStore((state) => [
    state.archive,
    state.setArchive,
    state.search,
    state.setSearch,
    state.setDate,
    state.setPage,
  ]);
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore((state) => [state.user]);
  const navigate = useNavigate();
  useEffect(() => {
    setSearch('')
  }, [])
  
  return (
    <Grid p={'lg'}>
      <Grid.Col span={5}>
        <Flex align={'center'}>
          {(defaultPermissions && checkPermission(defaultPermissions, ['add_campaign'])) && <Button size="xs" onClick={() => navigate(`/campaigns/${defaultWorkspace?._id}/setup`)}>
            Create new
          </Button>}
          <TextInput
            w={160}
            size="xs"
            value={search}
            onChange={(event) => {
              setPage(1)
              setSearch(event.currentTarget.value)
            }}
            ml={8}
            placeholder="Search campaign"
            leftSection={
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{
                  color: theme.colors.gray[5],
                }}
              />
            }
          />
        </Flex>
      </Grid.Col>
      <Grid.Col span={2}>
        <></>
      </Grid.Col>
      <Grid.Col span={5}>
        <Flex align={'center'} justify={'end'}>
          <DateMenu onChange={setDate} />
          <Space w={12} />
          <CampaignsColumnsMenu />
          <Space w={12} />
          {(defaultPermissions && checkPermission(defaultPermissions, ['add_campaign'])) && <Menu>
            <Menu.Target>
              <ActionIcon variant="outline" color="gray">
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown ml={-20}>
              <Menu.Item
                onClick={() => setArchive(!archive)}
                leftSection={
                  <>
                    <FontAwesomeIcon icon={archive ? faLink : faBoxArchive} />
                  </>
                }
              >
                {archive ? 'View Active Campaigns' : 'View Archived Campaigns'}
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>}
        </Flex>
      </Grid.Col>
    </Grid>
  );
}
