import { DateMenu } from '@/components/Menus/DateMenu/DateMenu';
import { CampaignsService } from '@/services/CampaignsService';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Box, Divider, Flex, Grid, Select, Text, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsByBrowser } from './Components/ShortLinks/AnalyticsByBrowser';
import { AnalyticsByDevice } from './Components/ShortLinks/AnalyticsByDevice';
import { AnalyticsByLocation } from './Components/ShortLinks/AnalyticsByLocation';
import { AnalyticsByReferral } from './Components/ShortLinks/AnalyticsByReferral';
import { AnalyticsHistogram } from './Components/ShortLinks/AnalyticsHistogram';
import { AnalyticsOverview } from './Components/ShortLinks/AnalyticsOverview';
import { endOfDay, startOfDay } from 'date-fns';

export const AnalyticsShortLinksPage = () => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const location = useLocation();
  const [campaigns] = useCampaignsStore((state) => [state.campaigns]);
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null);
  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const [linksSuggestions, setLinksSuggestions] = useState<any[]>([]);
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  /**
   * Handle date change
   * @param value
   */
  const handleDateChange = (data: any) => {
    if (data.label === 'All time') {
      queryParams.set(
        'from_date',
        startOfDay(new Date(defaultWorkspace?.created_at || '')).toISOString()
      );
      queryParams.set('to_date', endOfDay(new Date()).toISOString());
    } else {
      const splitValues = data.value.split('_');
      queryParams.set('from_date', splitValues[0]);
      queryParams.set('to_date', splitValues[1]);
    }
    const newSearch = `?${queryParams.toString()}`;
    navigate(newSearch);
  };

  const handleCampaignChange = (value: string | null) => {
    setSelectedCampaign(value);
    setSelectedLink(null);
    queryParams.set('campaign_id', value || '');
    queryParams.delete('link_id');
    const newSearch = `?${queryParams.toString()}`;
    navigate(newSearch);
  };

  const handleLinkChange = (value: string | null) => {
    setSelectedLink(value);
    queryParams.set('link_id', value || '');
    const newSearch = `?${queryParams.toString()}`;
    navigate(newSearch);
  };

  useEffect(() => {
    // search for the query params
    const campaignId = queryParams.get('campaign_id');
    const linkId = queryParams.get('link_id');
    setSelectedCampaign(campaignId);
    setSelectedLink(linkId);

    if (selectedCampaign) {
      new CampaignsService()
        .getLinksbyCampaignId(defaultWorkspace?._id || '', selectedCampaign)
        .then((res) => {
          if (res.data.status) {
            setLinksSuggestions(res.data.data);
          }
        });
    } else {
      setLinksSuggestions([]);
    }
  }, [selectedCampaign]);

  useEffect(() => {
    setSelectedCampaign('');
    setSelectedLink('');
  }, [defaultWorkspace?._id]);
  return (
    <>
      <Divider />
      <Flex
        mt={16}
        // p={16}
        // px={24}
        align={'center'}
        style={
          {
            //   background: 'var(--mantine-color-white)',
          }
        }
      >
        <Flex
          style={{
            flexGrow: 1,
          }}
        >
          <Select
            data={campaigns.map((campaign) => ({
              value: campaign._id,
              label: campaign.name,
            }))}
            placeholder="Filter by a campaign"
            size="sm"
            radius="sm"
            style={{ minWidth: 200 }}
            mr={12}
            searchable
            value={selectedCampaign}
            onChange={handleCampaignChange}
          />
          <Tooltip disabled={selectedCampaign !== null} label="Please select a campaign first">
            <Select
              data={linksSuggestions}
              disabled={selectedCampaign === null}
              placeholder="Filter by a link"
              size="sm"
              radius="sm"
              limit={25}
              searchable
              style={{ minWidth: 200 }}
              value={selectedLink}
              onChange={handleLinkChange}
            />
          </Tooltip>
        </Flex>
        <DateMenu onChange={handleDateChange} labelValue="Last 30 days" />
      </Flex>
      <Box my={16}>
        <Grid>
          <Grid.Col span={12}>
            <AnalyticsOverview />
          </Grid.Col>
          <Grid.Col span={12}>
            <Box
              h={306}
              bg={'white'}
              style={{
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                border: '1px solid var(--mantine-color-gray-2)',
                borderRadius: 4,
                padding: 16,
              }}
            >
              <Flex direction={'column'}>
                <Text fw={600} c="dimmed" mb={12}>
                  Clicks vs Unique Clicks vs QR Scans
                </Text>
                <AnalyticsHistogram />
              </Flex>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
      <Grid>
        <Grid.Col span={6}>
          <AnalyticsByReferral />
        </Grid.Col>
        <Grid.Col span={6}>
          <AnalyticsByLocation />
        </Grid.Col>
        <Grid.Col span={6}>
          <AnalyticsByBrowser />
        </Grid.Col>
        <Grid.Col span={6}>
          <AnalyticsByDevice />
        </Grid.Col>
      </Grid>
    </>
  );
};
