import { faCheck, faSpinner, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Text,
  Group,
  Title,
  Stack,
  Progress,
  Checkbox,
  TextInput,
} from '@mantine/core';

import { PaymentHistory } from './PaymentHistory';
import { SubscriptionsListing } from './SubscriptionsListing';
import { useDocumentTitle } from '@mantine/hooks';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, formatLimit, getPlanLimitsProgressColor, getPlanLimitsProgressValue } from '@/utils/CommonUtility';
import { useUserStore } from '@/stores/useUserStore';
import { usePlanStore } from '@/stores/usePlanStore';
import { PlansModal } from '@/components/Modals/PlansModal/PlansModal';
import { useEffect, useState } from 'react';
import { PlanService } from '@/services/PlanService';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { getRelativeTime } from '@/utils/DateUtility';
import { CancelSubscriptionModal } from '@/components/Modals/CancelSubscriptionModal/CancelSubscriptionModal';

export function BillingPage() {

  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [plan, upgradeModalState, primarySubscription, setUpgradeModalState, setPrimarySubscription, fetchPlan] =
    usePlanStore((state) => [
      state.plan,
      state.upgradeModalState,
      state.primarySubscription,
      state.setUpgradeModalState,
      state.setPrimarySubscription,
      state.fetchPlan
    ]);
  const [user] = useUserStore((state) => [state.user]);
  const [loading, setLoading] = useState(false);
  const [updatePlanLoader, setUpdatePlanLoader] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [changeTrialPlan, setChangeTrialPlan] = useState(false);

  const [subscriptionToCancel, setSubscriptionToCancel] = useState<any>();
  const [cancelSubscriptionModalState, setCancelSubscriptionModalState] = useState(false);

  useDocumentTitle('Plan and Billing | ' + defaultWorkspace?.agency?.name);

  const handleCancelSubscription = (subscription: any) => {
    setSubscriptionToCancel(subscription);
    setCancelSubscriptionModalState(true);
  };


  const cancelSubscription = (subscription: any, reasons: string[]) => {
    if (!defaultWorkspace || !user) {
      return;
    }

    if (subscription.status != 'active') {
      notifications.show({
        color: 'red',
        message: 'Subscription is not active.',
      });
      return;
    }

    new PlanService().cancelSubscription(defaultWorkspace._id, defaultWorkspace.organization_id, subscription.subscription_id, reasons).then((response) => {
      if (response.data?.status) {
        setCancelSubscriptionModalState(false);
        notifications.show({
          color: 'green',
          message: 'Your request to cancel subscription has been received. We will process it soon.',
        });
        // setTimeout(() => {
        //   fetchSubscriptions(defaultWorkspace._id, defaultWorkspace.organization_id);
        // }, 3000);
      } else {
        notifications.show({
          color: 'red',
          message: response.data?.message || "Something went wrong. Please try again later.",
        });
      }
    }).catch((error) => {
      console.error('Error cancelling subscription', error)
      notifications.show({
        color: 'red',
        message: error?.response?.data?.message || "Something went wrong. Please try again later.",
      });
    });
  }

  const handleUpdateSubscription = (subscription: any, action: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>{action == 'pause' ? 'Pause' : 'Resume'} Subscription</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => updateSubscription(subscription, action),
    });
  };

  const updateSubscription = (subscription: any, action: string) => {
    if (!defaultWorkspace || !user) {
      return;
    }

    setUpdatePlanLoader(true);
    new PlanService().updateSubscription(defaultWorkspace._id, defaultWorkspace.organization_id, subscription.subscription_id, action).then((response) => {
      if (response.data?.status) {
        notifications.show({
          color: 'green',
          message: 'Subscription has been updated successfully.',
        });
        setTimeout(() => {
          fetchPlan(defaultWorkspace._id, defaultWorkspace.organization_id);
          fetchSubscriptions(defaultWorkspace._id, defaultWorkspace.organization_id);
        }, 3000);
      } else {
        notifications.show({
          color: 'red',
          message: response.data?.message || "Something went wrong. Please try again later.",
        });
      }
    }).catch((error: any) => {
      console.error('Error updating subscription', error)
      notifications.show({
        color: 'red',
        message: error?.response?.data?.message || "Something went wrong. Please try again later.",
      });
    }).finally(() => setUpdatePlanLoader(false));
  }


  const fetchSubscriptions = (workspace_id: string, organization_id: string) => {
    setLoading(true);
    new PlanService().getSubscriptions(workspace_id, organization_id).then((response) => {
      if (response.data?.status) {
        setPrimarySubscription(response.data?.primary_subscription || {});
        setSubscriptions(response.data?.subscriptions || []);
      }
    }).catch((error) => {
      console.error('Error fetching subscriptions', error)
      notifications.show({
        color: 'red',
        message: error?.response?.data?.message || "Unable to fetch subscriptions. Please try again later.",
      });
    }).finally(() => setLoading(false));
  }

  const handleChangeTrialPlan = () => {
    setChangeTrialPlan(true);
    setUpgradeModalState(true);
  }


  useEffect(() => {
    if (defaultWorkspace?.organization_id) {
      fetchPlan(defaultWorkspace._id, defaultWorkspace.organization_id);
      fetchSubscriptions(defaultWorkspace._id, defaultWorkspace.organization_id);
    }
  }, [defaultWorkspace?._id]);

  return defaultPermissions && checkPermission(defaultPermissions, ['edit_plan']) ? (
    <>
      <PlansModal
        isOpen={upgradeModalState}
        primarySubscription={primarySubscription}
        showTrialPlans={changeTrialPlan}
        currentPlanId={primarySubscription?.subscription_plan_id}
        onClose={() => {
          setChangeTrialPlan(false);
          setUpgradeModalState(false);
        }}
        fetchSubscriptions={fetchSubscriptions}
      />

      <CancelSubscriptionModal
        opened={cancelSubscriptionModalState}
        onClose={() => setCancelSubscriptionModalState(false)}
        onConfirm={(reasons: string[]) => cancelSubscription(subscriptionToCancel, reasons)}
      />
      <Box
        style={{
          backgroundColor: 'var(--mantine-color-white)',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
          height: '100%',
          flexGrow: 1,
        }}
        ml={16}
        mr={16}
      >
        <Box p={16}>
          <Grid>
            <Grid.Col span={2}>
              <Title order={5} pt={2}>
                Billing and Plan{' '}
              </Title>
            </Grid.Col>
            <Grid.Col span={5}>
              <></>
            </Grid.Col>
            <Grid.Col span={5}>
              <Flex align={'center'} justify={'end'}>
                {updatePlanLoader ? (<Text>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </Text>) : (<>
                    {plan?.type_of == 'recurring' && (
                      <>
                        {primarySubscription?.status == 'active' ? (
                          <>
                            <Button variant="outline" size="xs" color="red" onClick={() => handleCancelSubscription(primarySubscription)}>
                              Cancel Subscription
                            </Button>
                            <Button ml={'sm'} variant="outline" size="xs" onClick={() => handleUpdateSubscription(primarySubscription, 'pause')}>
                              Pause Subscription
                            </Button>
                          </>
                        ) : primarySubscription?.status == 'paused' && (
                          <Button variant="outline" size="xs" color="green" onClick={() => handleUpdateSubscription(primarySubscription, 'resume')}>
                            Resume Subscription
                          </Button>
                        )}
                        {primarySubscription?.status == 'deleted' && (
                          <Button variant="outline" size="xs" onClick={() => setUpgradeModalState(true)}>
                            Renew Plan
                          </Button>
                        )}
                      </>
                    )}
                    {plan?.type_of == 'trial' && (
                      <>
                        <Text fs="md">
                          Your trial plan ends {getRelativeTime(plan?.trial_ends_at)}
                        </Text>


                        <Button ml={5} variant="outline" size="xs" onClick={() => handleChangeTrialPlan()}>
                          Change Trial Plan
                        </Button>
                      </>
                    )}
                    <Button ml={'sm'} size="xs" onClick={() => setUpgradeModalState(true)}>
                      Upgrade Now
                    </Button>
                </>
                )}
              </Flex>
            </Grid.Col>
          </Grid>
        </Box>
        <Divider color="gray.2" />
        <Grid
          gutter={0}
          style={{
            height: 'calc(100% - 70px)',
          }}
          styles={{
            inner: {
              height: '100%',
            },
          }}
        >
          <Grid.Col
            span={3.5}
            w={240}
            style={{
              width: '100%',
              height: '100%',
              borderRight: '1px solid var(--mantine-color-gray-3)',
            }}
          >
            <Flex justify={'center'} direction={'column'} h={70} p={16}>
              <Text size="xs">Current Plan</Text>
              <Text size="sm" fw={700} c="primary">
                {plan?.display_name}
              </Text>
            </Flex>
            <Divider color={'gray.3'} />

            <Box
              m={16}
              p={16}
              style={{
                backgroundColor: 'var(--mantine-color-gray-1)',
                borderRadius: '0.25rem',
              }}
            >
              <Group align={'center'} justify="space-between">
                <Text fw={700} size="xs">
                  Plan Limits
                </Text>
                <Flex justify={'end'}>
                  {updatePlanLoader ? (<Text>
                    <FontAwesomeIcon icon={faSpinner} spin />
                  </Text>) : (
                      <Button variant="outline" size="xs" onClick={() => setUpgradeModalState(true)}>
                        Upgrade Plan
                      </Button>
                  )}
                </Flex>
              </Group>
              <Divider mt={'sm'} mb={'sm'} color={'gray.3'} />
              <Stack>
                <Group justify="space-between">
                  <Text fw="bold" size="xs">
                    Links
                  </Text>
                  <Text fw="bold" size="xs">
                    {plan?.used_limits?.links}/{formatLimit(plan?.limits?.links)}
                  </Text>
                </Group>
                <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'links')} color={getPlanLimitsProgressColor(plan, 'links')} />

                <Group justify="space-between">
                  <Text fw="bold" size="xs">
                    Clicks
                  </Text>
                  <Text fw="bold" size="xs">
                    {plan?.used_limits?.clicks}/{formatLimit(plan?.limits?.clicks, 'clicks')}
                  </Text>
                </Group>
                <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'clicks')} color={getPlanLimitsProgressColor(plan, 'clicks')} />

                <Group justify="space-between">
                  <Text fw="bold" size="xs">
                    Workspaces (Brands)
                  </Text>
                  <Text fw="bold" size="xs">
                    {plan?.used_limits?.workspaces}/{formatLimit(plan?.limits?.workspaces)}
                  </Text>
                </Group>
                <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'workspaces')} color={getPlanLimitsProgressColor(plan, 'workspaces')} />

                <Group justify="space-between">
                  <Text fw="bold" size="xs">
                    Campaigns
                  </Text>
                  <Text fw="bold" size="xs">
                    {plan?.used_limits?.campaigns}/{formatLimit(plan?.limits?.campaigns)}
                  </Text>
                </Group>
                <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'campaigns')} color={getPlanLimitsProgressColor(plan, 'campaigns')} />

                <Group justify="space-between">
                  <Text fw="bold" size="xs">
                    Bio Links
                  </Text>
                  <Text fw="bold" size="xs">
                    {plan?.used_limits?.bio_links}/{formatLimit(plan?.limits?.bio_links)}
                  </Text>
                </Group>
                <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'bio_links')} color={getPlanLimitsProgressColor(plan, 'bio_links')} />

                <Group justify="space-between">
                  <Text fw="bold" size="xs">
                    Team Members
                  </Text>
                  <Text fw="bold" size="xs">
                    {plan?.used_limits?.team_members}/{formatLimit(plan?.limits?.team_members)}
                  </Text>
                </Group>
                <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'team_members')} color={getPlanLimitsProgressColor(plan, 'team_members')} />

                <Group justify="space-between">
                  <Text fw="bold" size="xs">
                    Retargeting Pxiels
                  </Text>
                  <Text fw="bold" size="xs">
                    {plan?.used_limits?.retargeting_pixels}/{formatLimit(plan?.limits?.retargeting_pixels)}
                  </Text>
                </Group>
                <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'retargeting_pixels')} color={getPlanLimitsProgressColor(plan, 'retargeting_pixels')} />

                <Group justify="space-between">
                  <Text fw="bold" size="xs">
                    Custom Domains
                  </Text>
                  <Text fw="bold" size="xs">
                    {plan?.used_limits?.custom_domains}/{formatLimit(plan?.limits?.custom_domains)}
                  </Text>
                </Group>
                <Progress size="sm" value={getPlanLimitsProgressValue(plan, 'custom_domains')} color={getPlanLimitsProgressColor(plan, 'custom_domains')} />

                <Group justify="space-between">
                  <Text fw="bold" size="xs">
                    Branding
                  </Text>
                  <Box>
                    {plan?.limits?.branding ? (
                      <FontAwesomeIcon color="green" icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon color="red" icon={faXmark} />
                    )}
                  </Box>
                </Group>

                <Group justify="space-between">
                  <Text fw="bold" size="xs">
                    White-label
                  </Text>
                  <Box>
                    {plan?.limits?.whitelabel ? (
                      <FontAwesomeIcon color="green" icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon color="red" icon={faXmark} />
                    )}
                  </Box>
                </Group>
              </Stack>
            </Box>
          </Grid.Col>
          <Grid.Col span={8.5}>
            <SubscriptionsListing
              loading={loading}
              subscriptions={subscriptions}
              cancelSubscription={handleCancelSubscription}
              updateSubscription={handleUpdateSubscription}
            />
            <PaymentHistory />
          </Grid.Col>
        </Grid>
      </Box>
    </>
  ) : ('');
}
