import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { useUTMStore } from '@/stores/useUTMStore';
import { ICampaign } from '@/types/campaigns';
import { Anchor, Button, Center, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { UTMPresetApplyOrCreate } from '../Modals/UTMPresetModal/UTMPresetApplyOrCreate';
import { ensureHttps } from '@/utils/LinkUtility';
import { DEFAULT_LINK_VALUES } from '@/constants/Constants';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

const LINK_ERROR_STATES = {
  NO_LINK: 'NO_LINK',
  INVALID_LINK: 'INVALID_LINK',
};

export const OriginalURLInput = ({ onLinkCreated }: { onLinkCreated: () => void }) => {
  const [linkError, setLinkError] = useState('false');
  const [link, setLink, linkModalOpen, setLinkModalOpen, createLink, createLoader] =
    useLinkSetupStore((state) => [
      state.link,
      state.setLink,
      state.openedModal,
      state.setOpenedModal,
      state.createLink,
      state.createLoading,
    ]);
  const [isUTMModalOpen, setUTMModalOpen, setUTMURL] = useUTMStore((state) => [
    state.setupModal,
    state.setSetupModal,
    state.setUTMURL,
  ]);
  // Workspace store
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [getDefaultCampaign] = useCampaignsStore((state) => [state.getDefaultCampaign]);
  const handleCreateLink = async () => {
    if (!link.url) {
      setLinkError(LINK_ERROR_STATES.NO_LINK);
      return;
    }

    const defaultCampaign = getDefaultCampaign();
    const created = await createLink(
      defaultWorkspace?._id || '',
      defaultCampaign?._id || '',
      !link.url.includes('http') && !link.url.includes('https') ? `https://${link.url}` : link.url
    );
    console.log(created);
    setLinkModalOpen(true);
    onLinkCreated();
  };

  useEffect(() => {
    return () => {
      setUTMModalOpen(false);
    };
  }, []);

  return (
    <>
      <TextInput
        variant="filled"
        error={
          linkError === LINK_ERROR_STATES.NO_LINK || linkError === LINK_ERROR_STATES.INVALID_LINK
        }
        //   radius="xl"
        value={link.url}
        onChange={(event) => {
          setLinkError('');
          const url = ensureHttps(event.currentTarget.value);
          setLink({
            ...link,
            url,
          });
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setLinkError('');
            handleCreateLink();
          }
        }}
        placeholder="Enter URL to shorten"
        rightSectionWidth={link.url.length > 0 ? 80 : 36}
        rightSection={
          <>
            {link.url.length > 0 && (
              <Anchor
                onClick={() => {
                  setUTMURL(link.url);
                  setUTMModalOpen(true);
                }}
                size="xs"
                fw="bold"
                mr={12}
              >
                {' '}
                + Add UTM
              </Anchor>
            )}
          </>
        }
      />
      <Center mt={12}>
        <Button disabled={createLoader} loading={createLoader} onClick={handleCreateLink} size="sm">
          Create
        </Button>
      </Center>
    </>
  );
};
