import { LoaderDataTable } from '@/components/Menus/LoaderDataTable/LoaderDataTable';
import { NoResultsDataTable } from '@/components/Menus/NoResultsDataTable/NoResultsDataTable';
import { ReportActivityModal } from '@/components/Modals/ReportActivityModal/ReportActivityModal';
import { ReportService } from '@/services/ReportService';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useUserStore } from '@/stores/useUserStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { getRelativeTime } from '@/utils/DateUtility';
import {
  faEllipsis,
  faMagnifyingGlass,
  faPencil,
  faSend,
  faTrash,
  faWavePulse,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Grid,
  Group,
  Menu,
  Modal,
  MultiSelect,
  Pagination,
  Pill,
  Table,
  TagsInput,
  Text,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useDebouncedValue, useDocumentTitle } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { zodResolver } from 'mantine-form-zod-resolver';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator } from '@/utils/CommonUtility';
import { useSearchParams } from 'react-router-dom';

const reportSchema = z.object({
  _id: z.string().nullable(),
  workspace_id: z.string(),
  campaigns: z
    .array(z.string().min(1))
    .min(1, { message: 'At least one campaign must be selected' }),
  name: z.string().min(2, { message: 'Name should have at least 2 letters' }),
  daily: z.boolean(),
  weekly: z.boolean(),
  monthly: z.boolean(),
  email_addresses: z.array(z.string()).min(1, { message: 'At least one email must be selected' }),
  // Additional fields can be added here if needed
});

export function ReportsPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Reports | ' + defaultWorkspace?.agency?.name);
  const [activityModalOpened, setActivityModalOpened] = useState<boolean>(false);
  const [activities, setActivities] = useState<any[]>([]);
  const [storeLoading, setStoreLoading] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [debounced] = useDebouncedValue(search, 200);
  const [reportType, setReportType] = useState<string>('active');
  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<any[]>([]);
  const theme = useMantineTheme();
  const [user] = useUserStore((state) => [state.user]);
  const [page, setPage] = useState<number>(1);
  const [lastPage, setLastPage] = useState<number>(1);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [searchParams] = useSearchParams();
  const [campaigns, setCampaigns] = useCampaignsStore((state) => [
    state.campaigns,
    state.setCampaigns,
  ]);
  const [opened, setOpened] = useState(false);
  const form = useForm({
    validate: zodResolver(reportSchema),
    initialValues: {
      _id: null,
      workspace_id: defaultWorkspace?._id,
      campaigns: campaigns.map((campaign) => campaign._id),
      name: '',
      daily: false,
      weekly: false,
      monthly: false,
      email_addresses: [],
    },
  });

  useEffect(() => {
    const action = searchParams.get('action');
    if (action === 'create') {
      setOpened(true);
    }
  }, [searchParams]);

  const handleFormSubmit = async (formValues: any) => {
    if (!formValues.daily && !formValues.weekly && !formValues.monthly) {
      notifications.show({
        color: 'red',
        message: 'Please select at least one frequency',
      });
      return;
    }

    console.log(formValues);

    // prepare the selected campaigns payload as shown in the below payload
    // from the store and selected form campaigns

    setStoreLoading(true);
    if (formValues._id) {
      await new ReportService()
        .update(defaultWorkspace?._id || '', formValues)
        .then((res) => {
          if (res.data.status) {
            const modifiedList = list.map((item) => {
              if (item._id === formValues._id) {
                return res.data.data;
              }
              return item;
            });
            setList(modifiedList);
            notifications.show({
              color: 'green',
              message: 'Report updated successfully',
            });
            form.reset();
            setOpened(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 422) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    } else {
      await new ReportService()
        .create(defaultWorkspace?._id || '', formValues)
        .then((res) => {
          if (res.data.status) {
            notifications.show({
              color: 'green',
              message: 'Report created successfully',
            });
            setList([...list, res.data.data]);
            form.reset();
            setOpened(false);
          }
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 422) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    }

    setStoreLoading(false);
  };

  const fetchReports = async () => {
    setLoading(true);
    await new ReportService()
      .get(defaultWorkspace?._id || '', search, page, 10, reportType !== 'active')
      .then((res) => {
        if (res.data.status) {
          setList(res.data.data.data);
          setLastPage(res.data.data.last_page);
        } else {
          setList([]);
          notifications.show({
            color: 'red',
            message: 'An error occured while fetching the reports. Please try again.',
          });
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const handleDispatchReport = async (id: string) => {
    await new ReportService()
      .dispatch(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          notifications.show({
            color: 'green',
            message: 'Report has been dispatched.',
          });
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact support',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *  Function to confirm the deletion of a rss.
   * @param id - The id of the rss to be deleted.
   */
  const confirmDispatchReport = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Send Report</Title>,
      children: (
        <>
          <Text size="sm">Are you sure you want to send a report now.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'primary' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDispatchReport(id),
    });
  };

  const handleReportDelete = async (id: string) => {
    await new ReportService()
      .delete(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          // Remove the item from the list
          const newList = list.filter((item) => item._id !== id);
          setList(newList);
          notifications.show({
            color: 'green',
            message: 'Report has been deleted.',
          });
        } else {
          notifications.show({
            color: 'red',
            message: 'Something went wrong. Please contact support',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  /**
   *  Function to confirm the deletion of a report.
   * @param id - The id of the report to be deleted.
   */
  const confirmReportDelete = async (id: string) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Report</Title>,
      children: (
        <>
          <Text size="sm">Are you sure? This process can not be undone.</Text>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleReportDelete(id),
    });
  };

  const fetchReportActivity = async (id: string) => {
    await new ReportService()
      .activity(defaultWorkspace?._id || '', id)
      .then((res) => {
        if (res.data.status) {
          setActivityModalOpened(true);
          setActivities(res.data.data);
        } else {
          notifications.show({
            color: 'red',
            message: 'An error occured while fetching the report activity. Please try again.',
          });
        }
      })
      .catch((err) => {
        if (err.response.data.message) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
        }
      });
  };

  const handleActivity = async (id: string) => {
    fetchReportActivity(id);
  };

  useEffect(() => {
    fetchReports();
  }, [page, reportType, debounced]);
  return (
    <>
      <ReportActivityModal
        opened={activityModalOpened}
        onClose={() => {
          setActivityModalOpened(false);
        }}
        data={activities}
      />
      <Modal
        size={640}
        opened={opened}
        onClose={() => setOpened(!opened)}
        title={<Title order={5}>{form.values._id ? 'Update' : 'New'} Report</Title>}
      >
        <Box p={16}>
          {/* <Flex align={'center'} justify={'center'}>
            <FontAwesomeIcon
              style={{
                color: theme.colors.primary[9],
                marginBottom: 16,
              }}
              icon={faFileChartPie}
              size={'2x'}
            />
          </Flex>
          <Text fw={600} ta="center" size="sm" mb={16}>
            Please enter details to {form.values._id ? 'create' : 'update'} a report.
          </Text> */}
          <Box mx={32}>
            <form onSubmit={form.onSubmit(handleFormSubmit)}>
              <TextInput
                mb={12}
                label={'Report name'}
                variant="filled"
                placeholder="Enter your report name i.e Weekly Report"
                {...form.getInputProps('name')}
              />
              <MultiSelect
                searchable
                mb={12}
                variant="filled"
                label="Campaigns"
                placeholder="Select your campaigns"
                data={campaigns.map((campaign) => ({
                  label: campaign.name,
                  value: campaign._id,
                }))}
                {...form.getInputProps('campaigns')}
              />
              <Text fz="sm" fw={500} pb={4}>
                How often would you like to receive the emails?
              </Text>
              <Box
                bg={'gray.1'}
                px={12}
                mb={12}
                style={{
                  borderRadius: 4,
                }}
              >
                <Group py={12}>
                  <Checkbox
                    size="xs"
                    checked={form.values.daily}
                    {...form.getInputProps('daily')}
                    label="Daily"
                  />
                  <Checkbox
                    size="xs"
                    checked={form.values.weekly}
                    {...form.getInputProps('weekly')}
                    label="Weekly"
                  />
                  <Checkbox
                    checked={form.values.monthly}
                    size="xs"
                    {...form.getInputProps('monthly')}
                    label="Monthly"
                  />
                </Group>
              </Box>

              <TagsInput
                mb={12}
                label="Where should we send an email to?"
                variant="filled"
                placeholder="Enter your email address."
                {...form.getInputProps('email_addresses')}
              />

              <Flex mt={32} align={'center'} justify={'center'}>
                <Button disabled={storeLoading} loading={storeLoading} type="submit" mr={12}>
                  {form.values._id ? 'Update' : 'Create'}
                </Button>
                <Button onClick={() => setOpened(false)} variant="outline" color="gray">
                  Cancel
                </Button>
              </Flex>
            </form>
          </Box>
        </Box>
      </Modal>
      <Flex align={'center'} pl={'lg'} pt={'xs'}>
        <Text size="xl" fw="bold" mr="sm">
          Reports
        </Text>
      </Flex>
      <Flex m="lg" align={'center'}>
        {(defaultPermissions && checkPermission(defaultPermissions, ['add_reports'])) && <Button size="xs" onClick={() => setOpened(true)}>
          Create new
        </Button>}
        <TextInput
          w={160}
          size="xs"
          ml={8}
          value={search}
          onChange={(event) => setSearch(event.currentTarget.value)}
          placeholder="Search for a report"
          leftSection={
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              style={{
                color: theme.colors.gray[5],
              }}
            />
          }
        />
      </Flex>
      <Flex
        direction={'column'}
        m={'md'}
        style={{
          background: 'var(--mantine-color-white)',
        }}
      >
        <Box
          style={{
            flexGrow: 1,
          }}
        >
          <Table stickyHeader verticalSpacing={'md'}>
            <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
              <Table.Tr>
                <Table.Th>Name</Table.Th>
                <Table.Th>Emails</Table.Th>
                <Table.Th ta="center">Frequency</Table.Th>
                {(defaultPermissions && checkPermission(defaultPermissions, ['edit_reports'])) && <Table.Th ta={'center'}>Actions</Table.Th>}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {loading ? (
                <>
                  <LoaderDataTable colSpan={4} />
                </>
              ) : (
                <>
                  {list.length === 0 && (
                    <>
                      <NoResultsDataTable
                        colSpan={4}
                        data={list}
                        noResultsMessage={
                          search.length === 0 && reportType === 'active' && list.length === 0
                            ? 'You do not have any reports. Please create one.'
                            : 'No archived reports found.'
                        }
                      />
                    </>
                  )}
                  {list.map((report) => (
                    <>
                      <Table.Tr
                        key={report._id}
                        style={{
                          opacity: reportType === 'active' ? 1 : 0.5,
                        }}
                      >
                        <Table.Td>
                          <Flex direction={'column'}>
                            <Flex align={'center'}>
                              <Text fz={13}>{report.name}</Text>
                            </Flex>
                            <Text pt={4} c="dimmed" fz={10}>
                              Created {getRelativeTime(report.created_at)} | Last modified{' '}
                              {getRelativeTime(report.updated_at)}
                            </Text>
                          </Flex>
                        </Table.Td>
                        <Table.Td>
                          <Flex align={'center'}>
                            {report.email_addresses.map((email: string) => (
                              <>
                                <Pill mr={4} key={email}>
                                  {email}
                                </Pill>
                              </>
                            ))}
                          </Flex>
                        </Table.Td>
                        <Table.Td ta="center">
                          <Flex align={'center'} justify={'center'}>
                            {report.daily && (
                              <>
                                <Text
                                  fz={13}
                                  pr={8}
                                  style={{
                                    marginRight: report.weekly || report.monthly ? 8 : 0,
                                    borderRight:
                                      report.weekly || report.monthly
                                        ? '1px solid var(--mantine-color-gray-4)'
                                        : '',
                                  }}
                                >
                                  {report.daily ? 'Daily' : ''}
                                </Text>
                              </>
                            )}

                            {report.weekly && (
                              <>
                                <Text
                                  fz={13}
                                  pr={8}
                                  style={{
                                    marginRight: report.monthly ? 8 : 0,
                                    borderRight: report.monthly
                                      ? '1px solid var(--mantine-color-gray-4)'
                                      : '',
                                  }}
                                >
                                  {report.weekly ? 'Weekly' : ''}
                                </Text>
                              </>
                            )}

                            <Text fz={13} pr={8}>
                              {report.monthly ? 'Monthly' : ''}
                            </Text>
                          </Flex>
                        </Table.Td>

                        {(defaultPermissions && checkPermission(defaultPermissions, ['edit_reports'])) && <Table.Td>
                          <Menu withArrow withinPortal>
                            <Menu.Target>
                              <Center>
                                <ActionIcon c="gray" variant="subtle">
                                  <FontAwesomeIcon icon={faEllipsis} />
                                </ActionIcon>
                              </Center>
                            </Menu.Target>
                            <Menu.Dropdown>
                              <Menu.Item
                                onClick={() => {
                                  // set form values
                                  console.log(report);
                                  form.setValues({
                                    _id: report._id,
                                    workspace_id: report.workspace_id,
                                    campaigns: report.campaigns,
                                    name: report.name,
                                    daily: report.daily,
                                    weekly: report.weekly,
                                    monthly: report.monthly,
                                    email_addresses: report.email_addresses,
                                  });
                                  console.log(form.values);
                                  setOpened(true);
                                }}
                                leftSection={<FontAwesomeIcon icon={faPencil} />}
                              >
                                Edit
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => {
                                  handleActivity(report._id);
                                }}
                                leftSection={<FontAwesomeIcon icon={faWavePulse} />}
                              >
                                Activity
                              </Menu.Item>
                              <Menu.Item
                                onClick={() => confirmDispatchReport(report._id)}
                                leftSection={<FontAwesomeIcon icon={faSend} />}
                              >
                                Send Report
                              </Menu.Item>
                              {/* {reportType === 'active' ? (
                                <>
                                  <Menu.Item
                                    onClick={() => handleArchiveReport(report._id)}
                                    leftSection={<FontAwesomeIcon icon={faArchive} />}
                                  >
                                    Archive
                                  </Menu.Item>
                                </>
                              ) : (
                                <>
                                  <Menu.Item
                                    onClick={() => handleUnarchiveReport(report._id)}
                                    leftSection={<FontAwesomeIcon icon={faArchive} />}
                                  >
                                    Unarchive
                                  </Menu.Item>
                                </>
                              )} */}

                              <Menu.Divider />
                              <Menu.Item
                                onClick={() => confirmReportDelete(report._id)}
                                c={'red'}
                                leftSection={<FontAwesomeIcon icon={faTrash} />}
                              >
                                Delete
                              </Menu.Item>
                            </Menu.Dropdown>
                          </Menu>
                        </Table.Td>}
                      </Table.Tr>
                    </>
                  ))}
                </>
              )}
            </Table.Tbody>
          </Table>
        </Box>
      </Flex>
      <Box mx={'sm'} pb={'lg'} mt={'sm'}>
        <Group justify="space-between">
          <Pagination size="xs" total={lastPage} onChange={setPage} />
        </Group>
      </Box>
    </>
  );
}
