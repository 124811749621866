import { Box, Divider, Grid, Space } from '@mantine/core';
import { DashboardAnalytics } from './DashboardAnalytics';
import { DashboardChromeExtension } from './DashboardChromeExtension';
import { DashboardContact } from './DashboardContact';
import { DashboardInfoCarousel } from './DashboardInfoCarousel';
import { DashboardBioLinkCard } from './DashboardBioLinkCard';
import { DashboardLinkCreation } from './DashboardLinkCreation';
import { DashboardLinks } from './DashboardLinks';
import { DashboardVideos } from './DashboardVideos';
import { useDocumentTitle } from '@mantine/hooks';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

export function DashboardPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  useDocumentTitle('Dashboard | ' + defaultWorkspace?.agency?.name);

  return (
    <>
      <Grid gutter={'md'} px={'sm'}>
        <Grid.Col span={9}>
          <Grid>
            <Grid.Col span={9}>
              <DashboardLinkCreation />
            </Grid.Col>
            <Grid.Col span={3}>
            <Box
            h={200}
            style={{
              borderRadius: '0.25rem',
              backgroundColor: 'var(--mantine-color-white)',
            }}
          >
              <DashboardBioLinkCard />
              </Box>
            </Grid.Col>
          </Grid>
        </Grid.Col>
        <Grid.Col span={3}>
          <Box
            h={200}
            style={{
              borderRadius: '0.25rem',
              backgroundColor: 'var(--mantine-color-white)',
            }}
          >
            <DashboardInfoCarousel />
          </Box>
        </Grid.Col>
        <Grid.Col span={9}>
          <DashboardLinks />
        </Grid.Col>
        <Grid.Col span={3}>
          <DashboardAnalytics />
          <Space my={16} c="transparent" />
          {!defaultWorkspace?.agency?.is_whitelabeled && <DashboardContact />}
        </Grid.Col>
        {/* <Grid.Col span={3}>
          <DashboardChromeExtension />
        </Grid.Col>
        <Grid.Col span={6}>
          <DashboardVideos />
        </Grid.Col> */}
      </Grid>
    </>
  );
}
