import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { Box, Button, Container, Flex, Group, Image, Loader, Stack, Text, Title } from '@mantine/core';
import { DeveloperAppService } from '@/services/DeveloperAppsService';

export function DeveloperAppAuthorizationPage() {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [client, setClient] = useState<any | null>(null);


  /**
   * Verify client
   * @param clientId
   * @parm redirectUri
   */
  const verifyClient = (clientId: string, redirectUri: string) => {
    // Verify the client
    new DeveloperAppService().verifyClient(clientId, redirectUri).then((res) => {
      console.log(res);
      if (!res?.data?.valid) {
        // Show error message
        notifications.show({
          color: 'red',
          message: 'Invalid client id or redirect uri',
        });
        console.error('Invalid client');
      } else if (res?.data?.client) {
        setClient(res.data.client);
      } else {
        // Show error message
        notifications.show({
          color: 'red',
          message: 'Invalid client id or redirect uri',
        });
        console.error('Invalid client');
      }
    }).catch((err) => {
      // Show error message
      notifications.show({
        color: 'red',
        message: err.response?.data?.message || 'An error occurred',
      });
      console.error(err);
    }).finally(() => {
      setLoading(false);
    });
  };

  /**
   * Approve the client
   */
  const approveClient = () => {
    new DeveloperAppService().approveClient(client._id).then((res) => {
      if (res.status === 200 && res.data.redirect_url) {
        // Redirect to the client's redirect url
        window.location.href = res.data.redirect_url;
      } else {
        // Show error message
        notifications.show({
          color: 'red',
          message: 'An error occurred',
        });
      }
    }).catch((err) => {
      // Show error message
      notifications.show({
        color: 'red',
        message: err.response?.data?.error_description || 'An error occurred',
      });
      console.error(err);
    });
  };

  /**
   * Reject the client
   */
  const rejectClient = () => {
    new DeveloperAppService().rejectClient(client._id).then((res) => {
      if (res.status === 200 && res.data.redirect_url) {
        // Redirect to the client's redirect uri
        window.location.href = res.data.redirect_url;
      } else {
        // Show error message
        notifications.show({
          color: 'red',
          message: 'An error occurred',
        });
      }
    }).catch((err) => {
      // Show error message
      notifications.show({
        color: 'red',
        message: err.response?.data?.message || 'An error occurred',
      });
      console.error(err);
    });
  };


  useEffect(() => {
    // Read the query params
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id');
    const redirectUri = urlParams.get('redirect_uri');
    const responseType = urlParams.get('response_type');
    const scope = urlParams.get('scope');

    console.debug("URL Params", clientId, redirectUri, responseType, scope)

    if (!clientId || !redirectUri || !responseType || !scope) {
      // Show error message
      notifications.show({
        color: 'red',
        message: 'Invalid request. Missing required parameters.',
      });
      console.error('Invalid request. Missing required parameters.');
      // navigate('/dashboard');
      return;

    }

    verifyClient(clientId, redirectUri);

  }, []); // This effect runs only once on component mount

  return (
    loading ? <Container h={600}>
      <Flex h={'100%'} align={'center'} justify={'center'}>
        <Flex align={'center'} direction={'column'}>
          <Loader />
          <Text mt={16} size="sm">
            Loading...
          </Text>
        </Flex>
      </Flex>
    </Container> : (
        <Flex
          align="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          <Box
            style={{
              maxWidth: 700,
              width: '100%',
              padding: 30,
              backgroundColor: 'white',
              borderRadius: 10,
              boxShadow: '0 0 10px rgba(0,0,0,0.1)'
            }}
          >
            {!client ?
              <Flex align={'center'} justify={'center'} direction={'column'}>

                <Text color="red" size="lg">Invalid client id or redirect uri</Text>

                <Button onClick={() => navigate('/')} color="blue">Go Home</Button>
              </Flex>
              : (
                <Stack align="center">
                  <Title order={2}>Authorization Request</Title>
                  <Image
                    src="https://storage.googleapis.com/replug-assets/web_app/52e92162-a8d2-4041-a4bf-e0deb6d24b74.jpg"
                    alt="Authorization"
                    w={300}
                    h={300}
                  />
                  <Text size="md">
                    Connect {client.name} to your account
                  </Text>
                  <Text size="sm">
                    <Text component="span" fw="bold">{client.name}</Text> is requesting permission to access your account.
                  </Text>

                  <Group align="center" m={5}>
                    <Button onClick={approveClient}>Authorize</Button>
                    <Button variant="outline" color="gray" onClick={rejectClient}>Cancel</Button>
                  </Group>
                </Stack>
              )}
          </Box >
        </Flex>
    )
  );
};
