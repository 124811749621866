import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from './Router';
import { theme } from './theme';
import { useEffect, useState } from 'react';
import { WhitelabelService } from './services/WhitelabelService';
import ReplugFavicon from './assets/images/logos/replug-favicon.svg';
import ReplugLogo from '@/assets/images/logos/replug-logo.svg';
import { isWhitelabelDomain, saveWhitelabelSettings } from './utils/CommonUtility';

export default function App() {

  const [loading, setLoading] = useState(false);
  /**
   * Get white label details from api and save to local storage
   * @param domain 
   */
  const getWhiteLabelDetails = async (domain: string) => {
    // get domain details from api
    new WhitelabelService().getByDomain(domain).then((response) => {
      if (response.status) {
        const whitelabel = {
          logo: response.data?.whitelabel?.logo || ReplugLogo,
          favicon: response.data?.whitelabel?.favicon || ReplugFavicon,
          name: response.data?.whitelabel?.name || 'Replug',
          is_whitelabeled: response.data?.whitelabel?.is_enabled || false
        }
        // save whitelabel data to local storage
        saveWhitelabelSettings(whitelabel, true);
      }
    }).catch((error) => {
      console.error("Error getting white label details", error);
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => {
    let hostname = window.location.hostname
    const domain = hostname.replace("www.", "");
    // check if white label domain
    // then get white label details from api
    // then save to local storage and update favicon and title
    if (isWhitelabelDomain()) {
      setLoading(true);
      getWhiteLabelDetails(domain);
    }
  }, []);

  return (
    <HelmetProvider>
      <MantineProvider theme={theme}>
        <ModalsProvider>
          <Notifications position="top-right" />
          {loading ? <div>Loading...</div> : <Router />}
        </ModalsProvider>
      </MantineProvider>
    </HelmetProvider>
  )
}
