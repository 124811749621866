import { QRCodeModal } from '@/components/Modals/QRCodeModal/QRCodeModal';
import { Navbar } from '@/components/Navbar/Navbar';
import { UserService } from '@/services/UserService';
import { WorkspaceService } from '@/services/WorkspaceService';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useCustomDomainsStore } from '@/stores/useCustomDomainsStore';
import { useTagsStore } from '@/stores/useTagsStore';
import { useUTMStore } from '@/stores/useUTMStore';
import { useUserStore } from '@/stores/useUserStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IWorkspace } from '@/types/workspace';
import { Box, Container, Flex, Loader, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission } from '@/utils/CommonUtility';
import { usePlanStore } from '@/stores/usePlanStore';
import { useIpAddressStore } from '@/stores/useIpAddressStore';
import { BillingBanner } from '@/components/Billing/BillingBanner';
import { DisabledAccountMessage } from '@/components/Billing/DisabledAccountMessage';

function MainLayout({ children, header = true }: { children: React.ReactNode; header?: boolean }) {
  const { workspaceId } = useParams();
  const [setUser] = useUserStore((state) => [state.setUser]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [setWorkspaces, defaultWorkspace, setDefaultWorkspace] = useWorkspaceStore((state) => [
    state.setWorkspaces,
    state.defaultWorkspace,
    state.setDefaultWorkspace,
  ]);
  const [plan, fetchPlan] = usePlanStore((state) => [state.plan, state.fetchPlan]);
  const [fetchTags] = useTagsStore((state) => [state.fetchTags]);
  const [fetchIpAddresses] = useIpAddressStore((state) => [state.fetchIpAddresses]);
  const [fetchUTMs] = useUTMStore((state) => [state.fetchUTMs]);
  const [fetchCampaigns] = useCampaignsStore((state) => [state.fetchCampaigns]);
  const [fetchCustomDomains] = useCustomDomainsStore((state) => [state.fetchCustomDomains]);
  const [isLoading, setLoading] = useState(true);
  const [isWorkspaceLoading, setIsWorkspaceLoading] = useState(true);
  const navigate = useNavigate();
  const [setDefaultPermissions] = usePermissionsStore((state) => [state.setDefaultPermissions]);

  const fetchProfile = async () => {
    const res = await new UserService()
      .getProfile()
      .then((response) => {
        if (response.data.status) {
          setUser(response.data.data.user);
          return true;
        }
        return false;
      })
      .catch((error) => {
        console.debug(error);

        return false;
      });

    if (!res) {
      // redirect to login page with redirect url including current path and query params
      const redirect = window.location.pathname + window.location.search;
      navigate('/login?redirect=' + redirect);
    }
    setLoading(false);
  };

  const fetchWorkspaces = async () => {
    await new WorkspaceService()
      .getAll()
      .then((res) => {
        // find default workspace
        let defaultWorkspace: IWorkspace | null = res.find(
          (item: IWorkspace) => item.is_default === true
        );

        // If no default workspace is found, set the first workspace as default workspace.
        if (!defaultWorkspace) {
          // eslint-disable-next-line prefer-destructuring
          defaultWorkspace = res[0];
        }

        // If the workspaceId is available in the route, redirect the user to that workspace.

        if (workspaceId) {
          const workspace = res.find((item: IWorkspace) => item._id === workspaceId);
          if (workspace) {
            defaultWorkspace = workspace;
          }
        }
        // set all workspaces and default workspace

        setWorkspaces(res);
        setDefaultWorkspace(defaultWorkspace);


        // NOTE: it was redirecting the user in all cases.
        // navigate(`/dashboard/${defaultWorkspace?._id}`);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsWorkspaceLoading(false);
  };

  const IsUserAccountActive = defaultWorkspace?.organization?.status === 'active';

  useEffect(() => {
    fetchProfile();
    fetchWorkspaces();
  }, []);

  useEffect(() => {
    if (!isWorkspaceLoading && defaultWorkspace?._id) {
      fetchPlan(defaultWorkspace._id, defaultWorkspace?.organization_id || '');
      fetchTags(workspaceId || defaultWorkspace?._id || '');
      fetchIpAddresses(workspaceId || defaultWorkspace?._id || '');
      fetchCampaigns(workspaceId || defaultWorkspace?._id || '');
      fetchUTMs(workspaceId || defaultWorkspace?._id || '');
      fetchCustomDomains(workspaceId || defaultWorkspace?._id || '');
      setDefaultPermissions(defaultWorkspace?._id || '')
    }
  }, [defaultWorkspace?._id, isWorkspaceLoading]);

  if (isLoading || isWorkspaceLoading) {
    return (
      <Container h={600}>
        <Flex h={'100%'} align={'center'} justify={'center'}>
          <Flex align={'center'} direction={'column'}>
            <Loader />
            <Text mt={16} size="sm">
              Loading...
            </Text>
          </Flex>
        </Flex>
      </Container>
    );
  }

  return (
    <>
      <Box
        style={{
          height: '100%',
          backgroundColor: '#f2f6f7',
        }}
      >
        {/** If user account is not active, show the appropriate message */}
        {!IsUserAccountActive ? (
          <DisabledAccountMessage />
        ) : (
          <>
              <QRCodeModal />
              {defaultPermissions && checkPermission(defaultPermissions, ['view_plan']) && header && (
                <BillingBanner />
              )}
              {header && <Navbar />}
              {children}
          </>
        )}
      </Box>
    </>
  );
}

export default MainLayout;
