import { Box, Flex, Group, Text, Title } from '@mantine/core';
import classes from '../../BioLinks.module.css';
import { BioThemeCard } from './BioThemeCard';
import { BIO_THEMES_VARIANTS, IThemeCard } from '@replug/utils';

export const BioThemes = ({ form, scrollIntoView }: { form: any; scrollIntoView: any }) => {
  const handleThemeChange = (theme: IThemeCard) => {
    const themeStore = {
      background_color: theme.backgroundColor,
      color: theme.textColor,
      background_style: theme.backgroundStyle,
      button: {
        background_color: theme.buttonStyles.backgroundColor,
        color: theme.buttonStyles.color,
        variant: theme.buttonStyles.variant,
        radius: theme.buttonStyles.radius,
        box_shadow: theme.buttonStyles.boxShadow,
      },
    };
    form.setFieldValue('theme', themeStore);
  };
  return (
    <>
      <Title order={4} my={'md'}>
        Select Theme
      </Title>
      <Group>
        {BIO_THEMES_VARIANTS.map((theme) => (
          <Flex direction="column">
            <BioThemeCard theme={theme} onClick={handleThemeChange} />
            <Title my={'sm'} fz={14} fw={600} ta="center">
              {theme.name}
            </Title>
          </Flex>
        ))}
        <Box
          className={classes.createTheme}
          mt={-40}
          onClick={() => {
            scrollIntoView({
              alignment: 'center',
            });
          }}
        >
          <Box className={classes.createThemeInner}>
            <Text ta={'center'} fw={500} tt={'uppercase'}>
              Create your own theme
            </Text>
          </Box>
        </Box>
      </Group>
    </>
  );
};
